import React from "react";

function RedirectDocusign() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="text-center" style={{ marginBottom: "80px" }}>
          <h1 className="new_tour_head_2" style={{ color: "#005b67" }}>
            Thank You
          </h1>
          <h5
            style={{
              color: "#545267",
              fontWeight: "500",
              fontStyle: "italic",
              marginBottom: "110px",
            }}
          >
            Your Signature has been captured successfully. You can now submit
            the Claim Form.
          </h5>
        </div>
      </div>
    </div>
  );
}

export default RedirectDocusign;

import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "reactstrap";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import base_url from "../../api/bootapi";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import images from "react-payment-inputs/images";
import "./PaymentModal.css";
import cancel from "../../assets/close.png";

const PaymentModal = ({
  isOpen,
  onRequestClose,
  amount,
  setAmount,
  enquiry_id,
  jwtToken,
  setbackdropopen,
  fetchEnquiryData,
}) => {
  const customStylesone = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const { register, handleSubmit } = useForm();

  const [cardDetails, setCardDetails] = useState({
    card_number: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_cvv: "",
  });

  const handleAmountChange = (e) => {
    let value = e.target.value;

    value = value.replace(/[^0-9.]/g, "");

    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
      value =
        value.substring(0, decimalIndex + 1) +
        value.substring(decimalIndex + 1).replace(/\./g, "");
    }

    setAmount(value);
  };

  const handleChangeCardNumber = (e) => {
    setCardDetails({
      ...cardDetails,
      card_number: e.target.value,
    });
  };

  const handleChangeExpiryDate = (e) => {
    setCardDetails({
      ...cardDetails,
      card_expiry_year: e.target.value,
    });
  };

  const handleChangeCVC = (e) => {
    setCardDetails({
      ...cardDetails,
      card_cvv: e.target.value,
    });
  };

  const onSubmit = () => {
    if (Number(amount) <= 0) {
      return;
    }
    const formData = new FormData();
    let submitData = {
      card_expiry_month: cardDetails.card_expiry_year.substring(0, 2),
      card_expiry_year: cardDetails.card_expiry_year.substring(5, 7),
      card_number: cardDetails.card_number,
      card_cvv: cardDetails.card_cvv,
      amount: Number(amount),
      payment_method: "card",
    };
    formData.append("data", JSON.stringify(submitData));

    setbackdropopen(true);
    axios
      .post(
        `${base_url.api1}/tourapp/make_payment?id=` + enquiry_id,
        formData,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            setbackdropopen(false);
            onRequestClose();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Payment Successful",
              showCloseButton: true,
              showConfirmButton: false,
            });
            fetchEnquiryData();
          } else {
            setbackdropopen(false);
            onRequestClose();
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Payment failed",
              showCloseButton: true,
              showConfirmButton: false,
            });
          }
        },
        (error) => {
          setbackdropopen(false);
          onRequestClose();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            showCloseButton: true,
          });
        }
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStylesone}
      contentLabel="Payment Modal"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="card_head">Card Details</p>
        <div>
          <img
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            src={cancel}
            onClick={onRequestClose}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group creatadmin" style={{ minHeight: "220px" }}>
          {/* Amount Field */}
          <div style={{ marginBottom: "15px" }}>
            <label className="card_title">Amount(in $)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Amount"
              value={amount}
              onChange={handleAmountChange}
              required
              style={{
                padding: "10px",
                width: "100%",
                boxSizing: "border-box",
                marginBottom: "15px",
              }}
            />
          </div>

          {/* Payment Details */}
          <PaymentInputsWrapper
            {...wrapperProps}
            styles={{
              fieldWrapper: {
                base: "margin-bottom: 0; min-height: 2.5rem;",
              },
              inputWrapper: {
                base: " height: 100%; padding: 1rem;  display:flex; flex-direction:column;  gap:20px;",
                errored: "border-color: maroon;",
                focused:
                  "border-color: grey; box-shadow: unset;   outline-offset: 2px;",
              },

              input: {
                cardNumber: "padding: 5px; text-align:center; width: 17rem ",
                expiryDate: "padding: 5px; text-align:center; width: 10rem",
                cvc: "padding: 5px; text-align:center; width: 5rem",
              },
            }}
          >
            <div>
              <p className="card_title">Debit/Credit</p>
              <div className="input_card">
                <input
                  {...getCardNumberProps({
                    onChange: handleChangeCardNumber,
                  })}
                />
                <svg {...getCardImageProps({ images })} />
              </div>
            </div>

            <div className="card_bottom">
              <div>
                <p className="card_title">Expire Date</p>

                <input
                  {...getExpiryDateProps({
                    onChange: handleChangeExpiryDate,
                  })}
                />
              </div>
              <div>
                <p className="card_title">CVV</p>
                <input
                  {...getCVCProps({
                    onChange: handleChangeCVC,
                  })}
                />
              </div>
            </div>
          </PaymentInputsWrapper>
        </div>

        <Button
          type="submit"
          style={{
            textTransform: "capitalize",
            width: "100%",
            padding: "20px 0",
            backgroundColor: "#7eb66b",
            fontFamily: "'Poppins', sans-serif",
            marginTop: "10px",
          }}
        >
          Pay Now
        </Button>
      </form>
    </Modal>
  );
};

export default PaymentModal;

import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    category:[],
  },
  reducers: {
    getCategory:(state,action)=>{
      state.category=action.payload
    },
    
    addtoCategory:(state,action)=>{
      state.category=[...state.category,action.payload]
    },
    removeCategory:(state,action)=>{
      const index=state.category.findIndex(
        (adminUser)=>adminUser.username===action.payload.id 
      )
      let newCategory=[...state.category]
      if(index>=0){
        newCategory.splice(index,1)
      }
      else{
        console.warn('Cant remove')
      }
      return{
        ...state,
        category:newCategory
      }
    },
    clearCategory:(state,action)=>{
      state.category=null;
    },
    updateCategory:(state,action)=>{
      const index=state.category.findIndex(
        (categoryData)=>categoryData.id===action.payload.id 
      )
      let newCategory=[...state.category]
      if(index>=0){
        newCategory.splice(index,1)
        newCategory.splice(index,0,action.payload.data)
      }
      else{
        console.warn('Cant remove')
      }
      // newCategory.push(action.payload.data)
      return{
        ...state,
        category:newCategory
      }
    }
  },
});

export const { getCategory,addtoCategory,removeCategory,clearCategory,updateCategory } = categorySlice.actions;

export const selectCategory = state => state.category.category;

export default categorySlice.reducer;

import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import { Button, Container, CssBaseline } from "@mui/material";
import AgreementContent from "./AgreementContent"; // Import AgreementContent

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const AgreementModal = ({
  isOpen,
  onRequestClose,
  onSignatureAdded,
  onPdfGenerated,
  setEnvelopeId,
  envolpeId,
  signUrl,
  setSignUrl,
  setIsDocusignModalOpen,
  setIsFetching,
  setDocumentFetched,
  agreementDetails,
}) => {
  const componentRef = useRef();

  const [backdropopen, setbackdropopen] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {" "}
      <Backdrop sx={{ color: "#fff", zIndex: "3000" }} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Agreement Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "90%",
            overflow: "auto",
          },

          overlay: {
            zIndex: 1999, // Ensuring overlay is below the modal content
          },
        }}
      >
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <div className="d-flex flex-column">
            <div className="d-flex">
              <div>
                <AgreementContent
                  ref={componentRef}
                  onSignatureAdded={onSignatureAdded}
                  onPdfGenerated={onPdfGenerated}
                  setEnvelopeId={setEnvelopeId}
                  envolpeId={envolpeId}
                  setbackdropopen={setbackdropopen}
                  onRequestClose={onRequestClose}
                  setSignUrl={setSignUrl}
                  signUrl={signUrl}
                  setIsDocusignModalOpen={setIsDocusignModalOpen}
                  setIsFetching={setIsFetching}
                  setDocumentFetched={setDocumentFetched}
                  agreementDetails={agreementDetails}
                />
              </div>

              <div>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePrint}
                  style={{ marginTop: "35px", marginLeft: "20px" }}
                >
                  Print
                </Button> */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onRequestClose}
                  style={{ marginTop: "35px", marginLeft: "20px" }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default AgreementModal;

import React, { useState, useEffect } from "react";
import Nav from "./../NavBar/Nav";
import Footer from "./../NavBar/Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "./../../api/bootapi";
import attachmenturl from "./../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { getTour, selectTour } from "./../Reducers/tourSlice";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import Parser from "html-react-parser";
import FooterNew from "../NavBar/FooterNew";

export default function TourTrips() {
  const [backdropopen, setbackdropopen] = useState(false);
  const [tourTitle, setTourTitle] = useState([]);
  const [allTour, setTourData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [availableCategory, setAvailableCategory] = useState(null);
  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getTourByCategoryId(params.category_id);
    getCategoryById(params.category_id);
  }, [params.category_id]);

  const getCategoryById = (value1) => {
    axios
      .get(
        `${base_url.api1}/tourapp/get_category_by_id?id=` + params.category_id
      )
      .then(
        (response) => {
          setAvailableCategory(response.data.category);
        },
        (error) => {
          //console.log(JSON.stringify(error.message));
        }
      );
  };
  const getTourByCategoryId = (value1) => {
    let body = {
      model: "Tour",
      category: params.category_id,
      status: "Active",
    };
    setbackdropopen(true);
    axios.post(`${base_url.api1}/tourapp/tour_category`, body).then(
      (response) => {
        setTourTitle(response.data.Tour[0].category);
        let slicedTour = [];
        for (var i = 0; i < response.data.Tour.length; i += 3) {
          let part_slice = response.data.Tour.slice(i, 3 + i);
          slicedTour.push(part_slice);
        }
        setTourData(slicedTour);
        setbackdropopen(false);
      },
      (error) => {
        // dispatch(getTour(null));
        setTourTitle();
        setTourData();
        setbackdropopen(false);
        //console.log(JSON.stringify(error));
      }
    );
  };

  const getData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?id=24` //Live
        // `${base_url.api1}/cmsapp/cms?id=66` //Test
      )
      .then(
        (response) => {
          //console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
          setBody(response.data.Cms.body);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  // //console.log(allTour);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Nav />
      <div style={{ position: "relative", height: "80vh" }}>
        {availableCategory?.image != null ? (
          <img
            src={attachmenturl + availableCategory.image}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
        ) : (
          <img
            src="assets/image/inside-header'.jpg"
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
        )}
        <div className="overlaybg">
          <div style={{ postion: "absolute" }}>
            <h1
              style={{
                width: "100%",
                marginTop: "200px",
                fontSize: "3.5rem",
                textAlign: "center",
                color: "#fff",
                fontFamily: " Caveat,cursive",
              }}
            >
              {tourTitle ? tourTitle : params.category}
            </h1>
          </div>
        </div>
      </div>

      {/* <div style={{ position: "relative" }}>
        
        <div className="hero-main" id="logo">
          <div className="hero-main-img">
            {availableCategory?.image != null?
              <img src={attachmenturl+availableCategory.image} alt="Header_Image" style={{height:'60vh'}}/>
            :
              <img src="assets/image/inside-header'.jpg" alt="Header_Image" />
            }
            <h1 className="tour_h1">{tourTitle ? tourTitle : params.category}</h1>
            <h1 className="tour_head">Go back in time. Stay right here.</h1>
            <p className="tour_head_text" style={{ padding: "0 15%"}}>Lizensk.  Sanz.  Kerestir.  Summe.  The majesty of your Chasidic heritage.  The worlds that were.  Your fathers flocked to these cities for spiritual life, year after year.  Today, you can walk their streets, follow their footsteps and return to where it all began—honoring your family, reliving your history, and enhancing your spirituality as you connect with the synagogues and sacred resting places of the fathers of the great Chasidic dynasties.  Linsoa offers regular tours before every major Chasidic historical date—and comprehensive private tours perfect for families and small groups.</p>
          </div>
        </div>
        <Nav />
      </div> */}
      <div className="travel_highlight">
        <Row className="mx-0 px-0">
          <Col md={12} className="px-0">
            <div className="travel_category2">
              <div className="text-center">
                <h1 className="tour_head">{bannerTitle}</h1>
                <p
                  className="tour_head_text hello"
                  style={{ padding: "0 15%" }}
                >
                  {Parser(body)}
                </p>

                <h1 className="tour_head">תהנה מהרוח</h1>
                <p className="tour_head_text hello">{Parser(bannerCaption)}</p>
              </div>
              <div className="container">
                {allTour != null ? (
                  allTour &&
                  allTour.map((pdata, index) => (
                    <div>
                      <div className="empty"></div>
                      <Row className="mx-0 px-0">
                        {pdata != null ? (
                          pdata &&
                          pdata.map((p, index) => (
                            <div className="col-lg-4">
                              <div
                                className="gallery_list_view2 mt-2"
                                style={{
                                  borderRadius: "10px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="colImage2"
                                  style={{ marginTop: "-5%" }}
                                >
                                  <div>
                                    {p.primary_image ? (
                                      <>
                                        <img
                                          src={attachmenturl + p.primary_image}
                                          width="300"
                                          height="200"
                                          onClick={() =>
                                            navigate(
                                              "/view-tour-details/" + p.id
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src="No_image_available.png"
                                          style={{
                                            width: "auto",
                                            height: "300px",
                                            marginLeft: "5%",
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="trip_price_btn">
                                    {p.with_airfare_price}
                                  </div>
                                  <div className="text-center">
                                    <h4 className="category_title">
                                      <b>{p.tour_names}</b>
                                    </h4>
                                    <div
                                      className="p_class2"
                                      dangerouslySetInnerHTML={{
                                        __html: p.tour_short_description,
                                      }}
                                    />
                                  </div>
                                  <hr />
                                  <div className="row mx-0">
                                    <div className="col-md-7">
                                      <p class="trip_buy_btn_data">
                                        <b>Duration: </b>
                                        {p.tour_duration}
                                      </p>
                                    </div>
                                    <div className="col-md-5">
                                      <button
                                        class="trip_buy_btn"
                                        onClick={() =>
                                          navigate("/view-tour-details/" + p.id)
                                        }
                                      >
                                        Book Now
                                      </button>
                                    </div>
                                  </div>
                                  {/* <Row>
                                  <Col md={6}>
                                    <p class='trip_buy_btn_data'><b>Duration: </b>{p.tour_duration}</p>
                                  </Col>
                                  <Col md={6}>
                                    <button class="trip_buy_btn" onClick={()=>navigate("/view-tour-details/"+p.id)}>Book Now</button>
                                  </Col>
                                </Row> */}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            <h1>Sorry, No Trips Available!</h1>
                          </div>
                        )}
                      </Row>
                      <div className="empty"></div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h1>Sorry, No Trips Available!</h1>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="travel_category3">
              <div className="text-center">
                <h1 className="tour_head">ADVENTURE & ACTIVITY</h1>
                <p className="tour_head_text">
                  Travel in the confort with an experience team providing you
                  top
                  <br />
                  service and grand hotels, transport and dining.
                </p>
                <div className="tour_activity">
                  <div className="row mx-0">
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-1.jpg" />
                        <p>
                          <b>Adventure</b>
                        </p>
                        <p className="p_class3">15 Destination</p>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-2.jpg" />
                        <p>
                          <b>Trekking</b>
                        </p>
                        <p className="p_class3">12 Destination</p>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-3.jpg" />
                        <p>
                          <b>Camp Fire</b>
                        </p>
                        <p className="p_class3">12 Destination</p>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-4.jpg" />
                        <p>
                          <b>Off Road</b>
                        </p>
                        <p className="p_class3">12 Destination</p>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-5.jpg" />
                        <p>
                          <b>Camping</b>
                        </p>
                        <p className="p_class3">12 Destination</p>
                      </div>
                    </div>
                    <div className="col-lg">
                      <div className="tour_adventure">
                        <img src="assets/image/icon-6.jpg" />
                        <p>
                          <b>Exploring</b>
                        </p>
                        <p className="p_class3">12 Destination</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="hero-main" id="logo">
                <div className="hero-main-img ">
                  <div className="container">
                    <Row style={{ padding: "0" }}>
                      <Col
                        md={4}
                        className="tour_achivement"
                        style={{ paddingLeft: "4%" }}
                      >
                        --- <img src="assets/image/left-side-grafic.jpg" alt="Header_Image" /> ---
                        <Row className="px-0">
                          --- <Col md={2}>{null}</Col> ---
                          <Col
                            md={12}
                            className="tour_achivement_col"
                            style={{ width: "90%", margin: "10% 25%" }}
                          >
                            <div className="achievement_text">
                              <h4>ACHIEVEMENT</h4>
                              <h3>
                                <b>
                                  Our Achievement
                                  <br />
                                  and Award
                                </b>
                              </h3>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Sed urna quam, convallis ac
                                nibh quis, ornare sagittis lorem. Etiam dictum
                                eros vel tortor ultrices lobortis.{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={8}>
                        <div className="tour_achivement_col2">
                          <ul>
                            <li>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Maecenas sed malesuada leo. Sed
                                in nibh nec massa hendrerit consectetur. Nulla
                                ac eros vel diam ornare suscipit. Vestibulum
                                tincidunt dapibus nibh ut mollis. Mauris vitae
                                gravida ligula, in convallis eros.
                              </p>
                            </li>
                            <li>
                              <div className="row mx-0">
                                <div className="col-sm-3">
                                  <img
                                    src="assets/image/listing-logo1.jpg"
                                    alt="Header_Image"
                                    className="img-fluid"
                                    style={{
                                      minHeight: "63px",
                                      width: "100px",
                                      height: "80px",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <img
                                    src="assets/image/listing-logo2.jpg"
                                    alt="Header_Image"
                                    className="img-fluid"
                                    style={{
                                      minHeight: "63px",
                                      width: "100px",
                                      height: "80px",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <img
                                    src="assets/image/listing-logo3.jpg"
                                    alt="Header_Image"
                                    className="img-fluid"
                                    style={{
                                      minHeight: "63px",
                                      width: "100px",
                                      height: "80px",
                                    }}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <img
                                    src="assets/image/listing-logo4.jpg"
                                    alt="Header_Image"
                                    className="img-fluid"
                                    style={{
                                      minHeight: "63px",
                                      width: "100px",
                                      height: "80px",
                                    }}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div> */}
          </Col>
        </Row>
      </div>
      <div>
        <FooterNew />
      </div>
    </React.Fragment>
  );
}

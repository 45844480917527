import React, { useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";

const GoogleAddressInput = ({ googleAddress, setGoogleAddress }) => {
  const [optionsList, setOptionsList] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);

  const handleAddressInputChange = (e) => {
    fetchSuggestions(e.target.value);
    setGoogleAddress(e.target.value);
    setIsOpenList(true);
  };

  const handleAddressChange = (option) => {
    setGoogleAddress(option.label);
    setOptionsList([]);
    setIsOpenList(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpenList(false);
    }, 100);
  };

  const fetchSuggestions = async (inputValue) => {
    if (!inputValue) {
      setOptionsList([]);
      return;
    }

    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
      );
      const data = response.data;
      const suggestions = data.suggestions.map((suggestion) => ({
        label: suggestion.postal_code
          ? `${suggestion.description},${suggestion.postal_code}`
          : suggestion.description,
        value: suggestion.place_id,
      }));
      setOptionsList(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setOptionsList([]);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        value={googleAddress}
        onChange={handleAddressInputChange}
        placeholder="Enter Address"
        className="search-input form-control"
        type="text"
        autoComplete="off"
        onBlur={handleInputBlur}
        required
      />
      <i
        className="fa fa-map-marker home_icon"
        style={{ position: "absolute", left: 15, top: 10 }}
      />
      {isOpenList && optionsList.length > 0 && (
        <ul className="search-results">
          {optionsList.map((option, index) => (
            <li
              key={index}
              onClick={() => handleAddressChange(option)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GoogleAddressInput;

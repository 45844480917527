import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./footer.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from "./../../api/bootapi";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Footerlogo from "../../assets/footer-logo.png";
import twitterlogo from "../../assets/twitter-icon.png";
import instalogo from "../../assets/insta-icon.png";
import facebooklogo from "../../assets/face-icon.png";
import newlogo from "./../../assets/newlogo.png";

export default function FooterNew() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [backdropopen, setbackdropopen] = useState(false);
  const [totalElementMenu, setTotalElementMenu] = useState();
  const [totalParentMenu, setTotalParentMenu] = useState();

  const [totalToursElementMenu, setTotalToursElementMenu] = useState();
  const [totalToursParentMenu, setTotalToursParentMenu] = useState();
  const navigate = useNavigate();

  const onSubmit = (data2) => {
    let email_id = data2.email_id;
    let status = 1;

    let data = {
      email_id,
      status,
    };
    postContact(data);
  };

  useEffect(() => {
    getAllFooterServicesMenu();
    getAllFooterToursMenu();
  }, []);

  const [upcomingTour, setupcomingTour] = useState();

  useEffect(() => {
    getAllTourList();
  }, []);

  const getAllTourList = (value1) => {
    axios.get(`${base_url.api1}/tourapp/trip_list?status=Active`, {}).then(
      (response) => {
        //console.log(response.data.tour);
        setupcomingTour(response.data.tour);
        // document.getElementById("myForm").reset();
      },
      (error) => {
        setupcomingTour(null);
        //console.log(JSON.stringify(error.response.data));
      }
    );
  };

  const postContact = (data) => {
    setbackdropopen(true);
    axios.post(`${base_url.api1}/tourapp/newsletter`, data).then(
      (response) => {
        setbackdropopen(false);
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "success",
          title: "Thank You For Subscribing",
          showCloseButton: true,
          showConfirmButton: false,
          timer: 1500,
        });
        // document.getElementById("myForm").reset();
      },
      (error) => {
        setbackdropopen(false);
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    );
  };

  const getAllFooterServicesMenu = (value1) => {
    axios.get(`${base_url.api1}/cmsapp/menu?alias=services-menu`, {}).then(
      (response) => {
        //console.log(response);

        // Check if 'Menu' property exists in the response
        if (response.data && response.data.Menu) {
          const menuId = response.data.Menu.id;

          axios.get(`${base_url.api1}/cmsapp/menu_list`, {}).then(
            (response) => {
              // dispatch(getFooterMenu(response.data.Menu));
              //console.log(response.data.Menu);

              // Find the menu object with the matching ID
              const matchedMenu = response.data.Menu.find(
                (menu) => menu.id == menuId
              );

              if (matchedMenu) {
                //console.log("Matched Menu:", matchedMenu);
                setTotalElementMenu(matchedMenu.menu_elements);
                const parentElementNames = matchedMenu.menu_elements
                  .filter((matchedMenu) => matchedMenu.level === "0")
                  .map((parentMenu) => ({
                    id: parentMenu.id,
                    name: parentMenu.element_name,
                  }));

                //console.log(parentElementNames);
                setTotalParentMenu(parentElementNames);
              } else {
                //console.log("Menu not found");
              }
              setbackdropopen(false);
            },
            (error) => {
              setbackdropopen(false);
              //console.log(JSON.stringify(error));
            }
          );
        } else {
          setbackdropopen(false);
        }
      },
      (error) => {
        setbackdropopen(false);
        //console.log("Error in first API call:", JSON.stringify(error));
      }
    );
  };

  const getAllFooterToursMenu = (value1) => {
    axios.get(`${base_url.api1}/cmsapp/menu?alias=tours-menu`, {}).then(
      (response) => {
        //console.log(response);

        // Check if 'Menu' property exists in the response
        if (response.data && response.data.Menu) {
          const menuId = response.data.Menu.id;

          axios.get(`${base_url.api1}/cmsapp/menu_list`, {}).then(
            (response) => {
              // dispatch(getFooterMenu(response.data.Menu));
              //console.log(response.data.Menu);

              // Find the menu object with the matching ID
              const matchedMenu = response.data.Menu.find(
                (menu) => menu.id == menuId
              );

              if (matchedMenu) {
                //console.log("Matched Menu:", matchedMenu);
                setTotalToursElementMenu(matchedMenu.menu_elements);
                const parentElementNames = matchedMenu.menu_elements
                  .filter((matchedMenu) => matchedMenu.level === "0")
                  .map((parentMenu) => ({
                    id: parentMenu.id,
                    name: parentMenu.element_name,
                  }));

                //console.log(parentElementNames);
                setTotalToursParentMenu(parentElementNames);
              } else {
                //console.log("Menu not found");
              }
              setbackdropopen(false);
            },
            (error) => {
              setbackdropopen(false);
              //console.log(JSON.stringify(error));
            }
          );
        } else {
          setbackdropopen(false);
        }
      },
      (error) => {
        setbackdropopen(false);
        //console.log("Error in first API call:", JSON.stringify(error));
      }
    );
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <footer class="page-footer font-small pt-4">
        <div class="container text-md-left">
          <div>
            <div class="row">
              <div class="col-md-5 mt-md-0 mt-3">
                <Link>
                  <img src={newlogo} alt="" className="img-fluid mb-4" />
                </Link>
                <p style={{ color: "white" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Vitae, voluptatem corporis error non
                </p>
                <div>
                  <img src={facebooklogo} style={{ padding: "10px 10px" }} />
                  <img src={twitterlogo} style={{ padding: "10px 10px" }} />
                  <img src={instalogo} style={{ padding: "10px 10px" }} />
                </div>
              </div>
              <div class="col-md-2 mb-md-0 mb-3">
                <p class="footer_heading">Services</p>
                <ul class="list-unstyled footer_ul_style">
                  {totalElementMenu != null
                    ? totalElementMenu &&
                      totalElementMenu.map((p, index) => (
                        <li style={{ color: "white" }}>
                          <a>- {p.element_name}</a>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
              {/* <div class="col-md-3 mb-md-0 mb-3">
                                <p class="footer_heading ">Tours</p>
                                <ul class="list-unstyled footer_ul_style">
                                    {upcomingTour != null ? (
                                        upcomingTour &&
                                        upcomingTour.map((p, index) => (
                                            <li style={{ color: 'white' }}><Link
                                                to={{
                                                    pathname: `/view-tour-pdf/${p.id}/`,
                                                    state: { tourDetails: p.tour_details, fromWhere: "upcoming_tour", tour_names: p.tour_names }
                                                }}
                                            >- {p.tour_names}</Link></li>
                                        ))
                                    ) : (
                                        null
                                    )}
                                </ul>
                            </div> */}
              <div class="col-md-5 mb-md-0 mb-3">
                <p class="footer_heading">Subscribe us</p>
                <div class="mb-5 flex-center">
                  {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p> */}
                  {/* <div className="emailid">
                    <input type="email" placeholder="Enter Your Email Address" />
                    <button style={{ marginTop: "5%" }}>SUBSCRIBE NOW</button>
                  </div> */}
                  <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="emailid" style={{ width: "100%" }}>
                      <input
                        type="email"
                        placeholder="Enter Your Email Address"
                        {...register("email_id", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                        })}
                        style={{
                          padding: "0.900rem",
                          color: "black",
                          backgroundColor: "#8884a7",
                          borderRadius: "20px",
                        }}
                      />
                      {errors.email_id && (
                        <p
                          style={{
                            color: "white",
                            textAlign: "left",
                            padding: "2px",
                          }}
                        >
                          Email is Required
                        </p>
                      )}
                      <button style={{ marginTop: "5%", width: "50%" }}>
                        {" "}
                        Subscribe Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="row_copyright">
        <div className="container">
          <div className="row mx-0 p-0">
            <hr style={{ color: "white" }} />
            <div
              className="col-md-12 d-flex align-items-center"
              style={{ justifyContent: "center" }}
            >
              <p className="mb-0">
                Linsoa Tours &copy; 2024 All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import { TextField, IconButton, MenuItem, Grid } from "@mui/material";
import {
  SwapHoriz,
  AddCircleOutline,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Card,
  CardBody,
  CardText,

  // Table,
} from "reactstrap";
import moment from "moment";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import { Col, Row, Button } from "reactstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import base_url from "../../api/bootapi";

import Swal from "sweetalert2";

import GoogleInput from "../Tours/GoogleInput";
import GoogleAddressInput from "../Tours/GoogleAddressInput";
const CustomizeItinerary = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();
  const location = useLocation();

  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [mobile, setMobile] = useState();
  const [totalPassenger, setTotalPassenger] = useState(null);
  const [vehicleLocation, setVehicleLocation] = useState();
  const [totalDistance, setTotalDistance] = useState();
  const [address, SetAddress] = useState(null);
  const [vehicleOption, setVehicleOption] = useState([]);
  const [selectedVehicleOption, setSelectedVehicleOption] = useState();

  const [selectedVehicleType, setSelectedVehicleType] = useState();
  const [vehicleData, setVehicleData] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [bookingData, setBookingData] = useState({});

  const [tripStart, setTripStart] = useState(null);
  const [tripEnd, setTripEnd] = useState(null);
  const [stages, setStages] = useState([]);
  const [bookingId, setBookingId] = useState();
  useEffect(() => {
    getBookingDetails();
  }, []);

  //   const getAllAmenities = () => {
  //     setbackdropopen(true);
  //     axios.get(`${base_url.api1}/tourapp/amenities`).then(
  //       (response) => {
  //         setAmenitiesList(response.data.Amenities);

  //         setbackdropopen(false);
  //       },
  //       (error) => {
  //         let errorResp = JSON.stringify(error.response.status);

  //         setAmenitiesList([]);
  //         setbackdropopen(false);
  //         //console.log(JSON.stringify(error));
  //       }
  //     );
  //   };

  const [userId, setUserId] = useState("");

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setUserId(numericValue);
  };

  const getBookingDetails = () => {
    setbackdropopen(true);

    axios
      .get(
        `${base_url.api1}/tourapp/vehicle_booking_request?id=${params.booking_id}`
      )
      .then((response) => {
        setbackdropopen(false);
        let bookingDetails = response.data.booking;
        console.log("Booking Details", bookingDetails.vehicle_id);
        setBookingId(bookingDetails.id);
        getVehicleDetails(bookingDetails.vehicle_id);

        setBookingData({
          first_name: bookingDetails.name,
          last_name: bookingDetails.last_name,
          group_name: bookingDetails.group_name,
          phone: bookingDetails.phone_number,
          email: bookingDetails.email_id,
          totalPassenger: bookingDetails.number_of_people,
          tourType: bookingDetails.tour_type,
          note: bookingDetails.additional_info,
          status: bookingDetails.status,
          distance: bookingDetails.distance,
          vehicle_id: bookingDetails.vehicle_id,
          address: bookingDetails.address,
          arrival: bookingDetails.arrival,
          departure: bookingDetails.departure,
        });
        setTripStart(bookingDetails.from_loc);
        setTripEnd(bookingDetails.to_loc);
        console.log("Vehicle Itinerary", bookingDetails.vehicle_itinerary);
        const itineraryDetails = bookingDetails?.vehicle_itinerary?.map(
          (stage, index) => {
            const stageData = {
              from: stage.from_loc,
              to: stage.to_loc,
              toDate: stage.arrival,
              fromDate: stage.departure,
              distance: stage.distance,
              id: stage.id,
            };

            return stageData;
          }
        );
        console.log("Itinerary Details", itineraryDetails);
        setStages(itineraryDetails);
        SetAddress(bookingDetails.address);
      })
      .catch((error) => {
        setbackdropopen(false);
        Swal.fire({
          icon: "error",
          title: "No Record Found",
          html: `No record found for Booking ID:<strong>${userId}</strong>`,
          confirmButtonText: "Ok",
        });
        setBookingData({});
      });
  };

  //   const getAmenityTitle = (keyValue) => {
  //     const amenity = amenitiesList.find(
  //       (amenity) => amenity.key_value === keyValue
  //     );
  //     return amenity ? amenity.title : null;
  //   };

  //   const getVehicleOption = (vehicleType) => {
  //     setSelectedVehicleType(vehicleType);
  //     setbackdropopen(true);
  //     if (vehicleType) {
  //       axios
  //         .get(
  //           `${base_url.api1}/tourapp/vehicle_dropdown?status=True&vehicle=` +
  //             vehicleType
  //         )
  //         .then((response) => {
  //           let vehicleDetails = response.data.Vehicle;
  //           //console.log("vehicle--->", vehicleDetails);
  //           setVehicleOption(vehicleDetails);

  //           setbackdropopen(false);
  //         })
  //         .catch((error) => {
  //           //console.log("Error");
  //           setbackdropopen(false);
  //         });
  //     }
  //     setbackdropopen(false);
  //   };

  const getVehicleDetails = (id) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/tourapp/vehicle?id=` + id)
      .then((response) => {
        let vehicleDetails = response.data.vehicle;
        //console.log("vehicleLocation-->", vehicleDetails.station_of_vehicle);
        setVehicleLocation(vehicleDetails.station_of_vehicle);
        // setVehicleData({
        //   availability: vehicleDetails.availability,
        //   color: vehicleDetails.color,
        //   created_date: vehicleDetails.created_date,
        //   id: vehicleDetails.id,
        //   number_of_seat: vehicleDetails.number_of_seat,
        //   cost_per_day: vehicleDetails.cost_per_day,
        //   cost_per_km: vehicleDetails.cost_per_km,
        //   distance_per_day: vehicleDetails.distance_per_day,
        //   type_of_vehicle: vehicleDetails.type_of_vehicle,
        //   updated_date: vehicleDetails.updated_date,
        //   vehicle_facility: vehicleDetails.vehicle_facility,
        //   vehicle_model: vehicleDetails.vehicle_model,
        //   vehicle_no: vehicleDetails.vehicle_no,
        //   station_of_vehicle: vehicleDetails.station_of_vehicle,
        //   manufactured_year: vehicleDetails.manufactured_year,
        // });
        // setSelectedAmenities(vehicleDetails.amenities);
        setbackdropopen(false);
      })
      .catch((error) => {
        //console.log("Error");
        setbackdropopen(false);
      });
  };

  const handleMobileInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length > 10) {
      return;
    }
    // setMobile(value);
    setBookingData({ ...bookingData, phone: value });
    setValue("contact", value, { shouldValidate: true });
  };
  const handlePassengerInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    setTotalPassenger(value);
  };

  //   const handleVehicleChange = (e) => {
  //     if (e.target.value !== "") {
  //       getVehicleDetails(e.target.value);
  //     }
  //     setSelectedVehicleOption(e.target.value);
  //   };

  const handleAddStage = () => {
    const newStage = {
      id: null,
      from: null,
      to: null,
      fromDate: null,
      toDate: null,
    };

    setStages([...stages, newStage]);
  };

  const handleRemoveStage = (index, id) => {
    if (stages.length > 1) {
      if (id !== null) {
        //console.log("Delete", id);
        removeStageData(id);
      }
      const updatedStage = stages.filter((_, i) => i !== index);
      setStages(updatedStage);
    }
  };

  const removeStageData = (id) => {
    setbackdropopen(true);

    axios
      .delete(`${base_url.api1}/tourapp/vehicle_itinerary_delete?id=${id}`)
      .then(() => {
        setbackdropopen(false);
      })
      .catch(() => {
        setbackdropopen(false);
      });
  };

  const handleInputChange = (index, field, value) => {
    //console.log("field", field);
    const newStages = [...stages];
    newStages[index][field] = value;
    setStages(newStages);
  };

  const handleSwap = (index) => {
    //console.log("stages", stages);
    const newStages = [...stages];
    [newStages[index].from, newStages[index].to] = [
      newStages[index].to,
      newStages[index].from,
    ];
    setStages(newStages);
  };

  const getDistance = async (origin, destination, index, type) => {
    try {
      const response = await axios.post(`${base_url.api1}/tourapp/distance`, {
        origins: origin,
        destinations: destination,
      });

      const distanceValue = response.data.data.distance.value;

      const kmDistance = distanceValue / 1000; // Convert to kilometers

      return kmDistance;
    } catch (error) {
      console.error(
        `Error fetching distance from ${origin} to ${destination}`,
        error
      );
      return false; // Return false to indicate error
    }
  };

  const calculateTotalDistance = async (stages) => {
    let totalDistance = 0;
    const updatedStages = [...stages];

    try {
      // First stage: Vehicle location to first 'from' location
      if (stages[0].from) {
        const distanceToFirstFrom = await getDistance(
          vehicleLocation,
          stages[0].from,
          0,
          "departure"
        );
        if (distanceToFirstFrom === false) {
          throw new Error(
            `Error calculating distance for 'departure' of first stage`
          );
        }
        totalDistance += distanceToFirstFrom;
      }

      // Iterate through each stage
      for (let i = 0; i < stages.length; i++) {
        const stage = stages[i];

        // Calculate distance from 'from' location to 'to' location
        if (stage.from && stage.to) {
          const distanceFromTo = await getDistance(
            stage.from,
            stage.to,
            i,
            "arrival"
          );
          if (distanceFromTo === false) {
            throw new Error(
              `Error calculating distance from '${stage.from}' to '${
                stage.to
              }' in 'arrival' of stage ${i + 1}`
            );
          }
          totalDistance += distanceFromTo;
          updatedStages[i].distance = distanceFromTo.toFixed(2);
          setStages(updatedStages);
        }

        // If not the last stage, calculate distance from current 'to' location to next 'from' location
        if (i < stages.length - 1 && stages[i].to && stages[i + 1].from) {
          const distanceToNextFrom = await getDistance(
            stages[i].to,
            stages[i + 1].from,
            i + 1,
            "departure"
          );
          if (distanceToNextFrom === false) {
            throw new Error(
              `Error calculating distance from '${stages[i].to}' to '${
                stages[i + 1].from
              }' in 'departure' between stage ${i + 1} and ${i + 2}`
            );
          }
          totalDistance += distanceToNextFrom;
        }
      }

      // Last stage: Last 'to' location to vehicle location
      const lastStage = stages[stages.length - 1];
      if (lastStage.to) {
        const finalDistance = await getDistance(
          lastStage.to,
          vehicleLocation,
          stages.length - 1,
          "arrival"
        );
        if (finalDistance === false) {
          throw new Error(
            `Error calculating final distance to '${vehicleLocation}'`
          );
        }
        totalDistance += finalDistance;
      }

      return totalDistance; // Return total distance calculated successfully
    } catch (error) {
      console.error("Error calculating total distance:", error);

      // Determine whether the error occurred in 'departure' or 'arrival'
      let errorMessage = "";
      let stageInfo = "";

      if (error.message.includes("'departure'")) {
        errorMessage = "Error in departure stage";
        stageInfo = error.message.match(/stage (\d+)/);
      } else if (error.message.includes("'arrival'")) {
        errorMessage = "Error in arrival stage";
        stageInfo = error.message.match(/stage (\d+)/);
      }

      const stageIndex = stageInfo ? stageInfo[1] : "";

      // Show SweetAlert with error message and stage details
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        footer: `${errorMessage} in stage ${stageIndex}`,
      });

      throw error; // Throw the error to handle in calling function
    }
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBookingData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onSubmit = async (data) => {
    setbackdropopen(true);
    let totalDistance = 0;
    try {
      totalDistance = await calculateTotalDistance(stages);

      if (totalDistance !== false) {
        totalDistance = totalDistance.toFixed(2);

        //console.log("Total distance:", totalDistance);
      } else {
        console.error("Failed to calculate total distance");
        setbackdropopen(false);
        return;
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setbackdropopen(false);
      return;
    }

    const itineraryDetails = stages.map((stage, index) => {
      const stageData = {
        from_loc: stage.from,
        to_loc: stage.to,
        arrival: stage.toDate,
        departure: stage.fromDate,
        distance: stage.distance,
      };

      if (stage.id !== null) {
        stageData.id = stage.id;
      }

      return stageData;
    });

    //console.log("itinerary", itineraryDetails);

    const data1 = {
      name: bookingData.first_name,
      last_name: bookingData.last_name,
      group_name: bookingData.group_name,
      phone_number: bookingData.phone,
      email_id: bookingData.email,
      number_of_people: null,
      tour_type: null,
      additional_info: bookingData.note,
      status: bookingData.status,
      itinerary: itineraryDetails,
      distance: bookingData.distance,
      address: address,
      from_loc: tripStart,
      to_loc: tripEnd,
      departure: bookingData.departure,
      arrival: bookingData.arrival,
    };

    //console.log("data", data1);

    console.log("Sending Data", data1);

    axios
      .put(
        `${base_url.api1}/tourapp/vehicle_booking_request?id=${bookingId}`,
        data1
      )
      .then(
        (response) => {
          //console.log(response);
          if (response.status === 200) {
            setbackdropopen(false);

            Swal.fire({
              icon: "info",
              title: "Your Itinerary Details are Updated",
              html: `
            <div style="text-align: center;">
              <h3>Booking ID: ${bookingData.phone}</h3>
              <p>Total Distance: ${totalDistance} KM</p>
            </div>
          `,
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/thank-you-page", {
                state: { isClaimed: 2 },
              });
            });

            // Swal.fire({
            //   icon: "info",
            //   title: "Booking Request Received",
            //   text: "Your booking request has been received successfully",
            //   confirmButtonText: "OK",
            // }).then(() => {
            //   navigate({
            //     pathname: "/thank-you-page",
            //     state: { isClaimed: 2 },
            //   });
            // });

            document.getElementById("myForm").reset();
          } else {
            setbackdropopen(false);
          }
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Network Error",
            text: "There was a problem connecting to the server. Please try again later.",
          });

          document.getElementById("myForm").reset();
          setbackdropopen(false);
        }
      );
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />

        <div className="container">
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" class="b-link">
                Plan Your Trip
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="/itinerary" class="b-link">
                Search Customized Itinerary
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" class="b-link active">
                Customized Itinerary
              </Link>
            </ul>
          </div>
          {/* <div
            className="d-flex justify-content-center align-items-center"
            style={{
              marginBottom:
                Object.keys(bookingData).length > 0 ? "30px" : "120px",
            }}
          >
            <div className="input-group mb-3" style={{ width: "30%" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Booking ID"
                value={userId}
                onChange={handleSearchInputChange}
                style={{
                  borderRadius: "20px 0 0 20px",
                  borderColor: "#007bff",
                }}
              />
              <div className="input-group-append">
                <Button
                  color="primary"
                  type="button"
                  onClick={handleCheckStatus}
                  style={{ borderRadius: "0 20px 20px 0" }}
                >
                  Check Status
                </Button>
              </div>
            </div>
          </div> */}

          <Row className="px-0">
            <Col lg={12} className="carosol_img">
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        value={bookingData.last_name}
                        name="last_name"
                        onChange={inputEvent}
                        className="form-control"
                        // {...register("last_name", {
                        //   required: true,
                        // })}
                        required
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        type="text"
                        value={bookingData.first_name}
                        name="first_name"
                        onChange={inputEvent}
                        required
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>Trip Name</label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        type="text"
                        value={bookingData.group_name}
                        name="group_name"
                        onChange={inputEvent}
                      />
                      <i className="fa fa-users designi" />
                    </div>
                  </div>

                  <Col md={4}>
                    <label>Address:</label>
                    <GoogleAddressInput
                      googleAddress={address}
                      setGoogleAddress={SetAddress}
                    />
                  </Col>

                  <Col md={4}>
                    <label>
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={bookingData.phone}
                        className="form-control"
                        type="text"
                        name="phone"
                        onChange={handleMobileInputChange}
                        required // Move onChange handler here
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.contact && (
                      <p className="errormessage">{errors.contact.message}</p>
                    )}
                  </Col>
                  <div className="col-md-4">
                    <label>
                      Email Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        value={bookingData.email}
                        type="email"
                        name="email"
                        onChange={inputEvent}
                        required
                      />
                      <i className="fa fa-envelope designi" />
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <label htmlFor="vehicle-select">Select Vehicle:</label>
                    <div>
                      <select
                        id="note"
                        onChange={handleVehicleChange}
                        className="form-control"
                        required
                      >
                        <option value="">Select a vehicle</option>
                        {vehicleOption?.map((vehicle) => (
                          <option key={vehicle.id} value={vehicle.id}>
                            {`${vehicle.vehicle_model} (${vehicle.type_of_vehicle})`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  {/* <Col md={4}>
                    <label>Number of passengers</label>
                    <div style={{ position: "relative" }}>
                      <input
                        value={totalPassenger}
                        className="form-control"
                        placeholder="Number of passengers"
                        type="text"
                        onChange={handlePassengerInputChange} // Move onChange handler here
                      />

                      <i className="fa fa-users designi" />
                    </div>
                  </Col>
                  <div className="col-md-4">
                    <label>Type of Trip</label>
                    <div style={{ position: "relative" }}>
                      <select
                        className="form-control"
                        {...register("tripType", {
                          required: false,
                        })}
                      >
                        <option value="">Select</option>
                        <option value="Tourist Trip">Tourist Trip</option>
                        <option value="Sport Event">Sport Event</option>
                        <option value="Business Trip">Business Trip</option>
                        <option value="School Trip">School Trip</option>
                        <option value="Airport Transfer">
                          Airport Transfer
                        </option>
                        <option value="Wedding">Wedding</option>
                      </select>
                      <i className="fa fa-suitcase designi" />
                    </div>
                  </div> */}
                </div>
                <div className="row mr-3">
                  <p className="tour_head_text_3">Trip Summary</p>
                  <hr style={{ width: "20%" }} />
                  <div className="mb-4">
                    <div className="row align-items-center">
                      <div className="col-md-3">
                        <label>
                          Start point
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <GoogleAddressInput
                          googleAddress={tripStart}
                          setGoogleAddress={setTripStart}
                        />
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex justiy-content-start">
                          <div style={{ position: "relative" }}>
                            <label>
                              Start Date and Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              {...register("departure", {
                                required: false,
                              })}
                              value={moment(bookingData.departure).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              )}
                              onChange={inputEvent}
                              required
                            />
                            <i
                              className="fa fa-clock-o designi"
                              style={{ top: "34px", left: "10px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label>
                          Finish point
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <GoogleAddressInput
                          googleAddress={tripEnd}
                          setGoogleAddress={setTripEnd}
                        />
                      </div>

                      <div className="col-md-3">
                        <div className="d-flex justiy-content-start">
                          <div style={{ position: "relative" }}>
                            <label>
                              Finish Date and Time
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              {...register("arrival", {
                                required: false,
                              })}
                              value={moment(bookingData.arrival).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              )}
                              onChange={inputEvent}
                              required
                            />
                            <i
                              className="fa fa-clock-o designi"
                              style={{ top: "34px", left: "10px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {stages?.length > 0 && (
                  <div className="row mr-3">
                    <p className="tour_head_text_3">Itinerary Details</p>
                    <hr style={{ width: "20%" }} />
                    <div>
                      {stages.map((stage, index) => (
                        <div key={index} className="mb-4">
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <label>
                                Start point
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <GoogleInput
                                placeholder="Enter From Address"
                                setValue={(value) =>
                                  handleInputChange(index, "from", value)
                                }
                                currValue={stage.from}
                                setStages={setStages}
                                stages={stages}
                                index={index}
                                field="from"
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="d-flex justiy-content-start">
                                <div style={{ position: "relative" }}>
                                  <label>
                                    Start Date and Time
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="datetime-local"
                                    className="form-control"
                                    value={moment(stage.fromDate).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "fromDate",
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                  <i
                                    className="fa fa-clock-o designi"
                                    style={{ top: "34px", left: "10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-1 text-center">
                            <Button
                              color="primary"
                              onClick={() => handleSwap(index)}
                              style={{
                                width: "65%",
                                backgroundColor: "#1976d2",
                                color: "white",
                                marginTop: "20px",
                                borderRadius: "50%",
                              }}
                            >
                              <SwapHoriz />
                            </Button>
                          </div> */}
                            <div className="col-md-3">
                              <label>
                                Finish point
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <GoogleInput
                                placeholder="Enter To Address"
                                setValue={(value) =>
                                  handleInputChange(index, "to", value)
                                }
                                currValue={stage.to}
                                setStages={setStages}
                                stages={stages}
                                index={index}
                                field="to"
                              />
                            </div>
                            {/* <div className="col-md-2">
                            <div style={{ position: "relative" }}>
                              <label>
                                Departure/Arrival
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                value={stage.dateType}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "fromDate",
                                    e.target.value
                                  )
                                }
                                required
                              >
                                <option value="Arrival">Arrival</option>
                                <option value="Departure">Departure</option>
                              </select>
                              <i
                                className="fa fa-plane designi"
                                style={{ top: "34px", left: "10px" }}
                              />
                            </div>
                          </div> */}
                            <div className="col-md-3">
                              <div className="d-flex justiy-content-start">
                                <div style={{ position: "relative" }}>
                                  <label>
                                    Finish Date and Time
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="datetime-local"
                                    className="form-control"
                                    value={moment(stage.toDate).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "toDate",
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                  <i
                                    className="fa fa-clock-o designi"
                                    style={{ top: "34px", left: "10px" }}
                                  />
                                </div>
                                <div className=" text-center">
                                  {stages.length > 1 && (
                                    <div
                                      onClick={() =>
                                        handleRemoveStage(index, stage.id)
                                      }
                                      style={{
                                        backgroundColor: "none",
                                        color: "red",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                        marginTop: "30px",
                                      }}
                                    >
                                      <RemoveCircleOutline />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Button
                        color="primary"
                        onClick={handleAddStage}
                        style={{ marginTop: "10px", width: "200px" }}
                      >
                        <AddCircleOutline /> Add next stage
                      </Button>
                    </div>
                  </div>
                )}

                {/* <div className="row mr-3">
                    <p className="tour_head_text_3">Booking Details</p>
                    <hr style={{ width: "20%" }} />
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="vehicleType"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Vehicle Type:
                        </label>
                        <select
                          name="type_of_vehicle"
                          className="form-select"
                          value={selectedVehicleType}
                          onChange={(e) => getVehicleOption(e.target.value)}
                        >
                          <option value="doublebus">Double Decker Bus</option>
                          <option value="bus">Bus</option>
                          <option value="sprinter">Sprinter</option>
                          <option value="minivan">MiniVan</option>
                          <option value="car">Car</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="vehicle-select">Select Vehicle:</label>
                        <div>
                          <select
                            id="note"
                            onChange={handleVehicleChange}
                            value={selectedVehicleOption}
                            className="form-select"
                            required
                          >
                            <option value="">Select a vehicle</option>
                            {vehicleOption && vehicleOption.length > 0 ? (
                              vehicleOption.map((vehicle) => (
                                <option key={vehicle.id} value={vehicle.id}>
                                  {`${vehicle.vehicle_model}`}
                                </option>
                              ))
                            ) : (
                              <option disabled>No options available</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {selectedVehicleOption && (
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={6}>
                                  <CardText>
                                    <b>Type of Vehicle:</b>{" "}
                                    {vehicleData.type_of_vehicle}
                                  </CardText>
                                  <CardText>
                                    <b>Vehicle Model:</b>{" "}
                                    {vehicleData.vehicle_model}
                                  </CardText>
                                  <CardText>
                                    <b>Manufactured Year:</b>{" "}
                                    {vehicleData.manufactured_year}
                                  </CardText>
                                  <CardText>
                                    <b>Color:</b> {vehicleData.color}
                                  </CardText>
                                  <CardText>
                                    <b>Vehicle Number:</b>{" "}
                                    {vehicleData.vehicle_no}
                                  </CardText>
                                  <CardText>
                                    <b>Number of Seats:</b>{" "}
                                    {vehicleData.number_of_seat}
                                  </CardText>
                                </Col>
                                <Col md={6}>
                                  <CardText>
                                    <b>Location of Vehicle:</b>{" "}
                                    {vehicleData.station_of_vehicle}
                                  </CardText>
                                  <CardText>
                                    <b>Cost per KM:</b> $
                                    {vehicleData.cost_per_km}
                                  </CardText>
                                  <CardText>
                                    <b>Cost per Day:</b> $
                                    {vehicleData.cost_per_day}
                                  </CardText>
                                  <CardText>
                                    <b>Min Km per Day:</b>{" "}
                                    {vehicleData.distance_per_day} KM
                                  </CardText>
                                  <CardText>
                                    <b>Availability:</b>{" "}
                                    {vehicleData.availability
                                      ? "Available"
                                      : "Not Available"}
                                  </CardText>
                                  {selectedAmenities.length > 0 && (
                                    <CardText>
                                      <b>Amenities:</b>{" "}
                                      {selectedAmenities
                                        .map((key) => getAmenityTitle(key))
                                        .filter((amenity) => amenity)
                                        .join(", ")}
                                    </CardText>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div> */}
                <div className="row">
                  <div className="col-md-8">
                    <label>Note:</label>
                    <textarea
                      className="form-control form-control-sm"
                      {...register("note", {
                        required: false,
                      })}
                      value={bookingData.note}
                      onChange={inputEvent}
                      style={{
                        minHeight: "150px",
                        fontSize: "18px",
                      }}
                      id="note"
                    />
                  </div>
                </div>
                <div style={{ padding: "0 20px", marginBottom: "50px" }}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        type="submit"
                        style={{
                          padding: "10px 40px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        color="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>

          <div className="tourdetails_grid"></div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
};

export default CustomizeItinerary;

import { createSlice } from '@reduxjs/toolkit';

export const tourSlice = createSlice({
  name: 'tour',
  initialState: {
    tour:[],
  },
  reducers: {
    getTour:(state,action)=>{
      state.tour=action.payload
    },
    
    addtoTour:(state,action)=>{
      state.tour=[...state.tour,action.payload]
    },
    removeTour:(state,action)=>{
      const index=state.tour.findIndex(
        (adminUser)=>adminUser.username===action.payload.id 
      )
      let newTour=[...state.tour]
      if(index>=0){
        newTour.splice(index,1)
      }
      else{
        console.warn('Cant remove')
      }
      return{
        ...state,
        tour:newTour
      }
    },
    clearTour:(state,action)=>{
      state.tour=null;
    },
    updateTour:(state,action)=>{
      const index=state.tour.findIndex(
        (tourData)=>tourData.id===action.payload.id 
      )
      let newTour=[...state.tour]
      if(index>=0){
        newTour.splice(index,1)
        newTour.splice(index,0,action.payload.data)
      }
      else{
        console.warn('Cant remove')
      }
      // newTour.push(action.payload.data)
      return{
        ...state,
        tour:newTour
      }
    }
  },
});

export const { getTour,addtoTour,removeTour,clearTour,updateTour } = tourSlice.actions;

export const selectTour = state => state.tour.tour;

export default tourSlice.reducer;
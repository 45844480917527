import React, { useState } from "react";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import base_url from "../../api/bootapi";

function EnquiryStatus() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enquiries, setEnquiries] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 10) {
      return;
    }
    setPhoneNumber(numericValue);
  };

  const handleCheckStatus = () => {
    if (!phoneNumber) {
      return;
    }
    setbackdropopen(true);

    axios
      .post(`${base_url.api1}/tourapp/user_enquiry_view`, {
        phone_number: phoneNumber,
      })
      .then((response) => {
        setbackdropopen(false);
        setEnquiries(response?.data?.tour || []);
        setPhoneNumber("");
      })
      .catch((error) => {
        setbackdropopen(false);
        Swal.fire({
          icon: "error",
          title: "No Record Found",
          html: `No record found for Phone Number:<strong>${phoneNumber}</strong>`,
          confirmButtonText: "Ok",
        });
        setPhoneNumber("");
      });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div style={{ margin: "35px 0" }}>
        <div className="container mt-4">
          <div
            className="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul className="breadcrums">
              <Link to="/" className="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" className="b-link active">
                My Booking
              </Link>
            </ul>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow">
                <div className="card-body" style={{ minHeight: "400px" }}>
                  <h5
                    className="card-title text-center mb-4"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#007bff",
                    }}
                  >
                    Check Your Booking
                  </h5>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter 10-Digit TelePhone NO./Profile ID"
                      value={phoneNumber}
                      onChange={handleInputChange}
                      style={{
                        borderRadius: "20px 0 0 20px",
                        borderColor: "#007bff",
                      }}
                    />
                    <div className="input-group-append">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleCheckStatus}
                        style={{ borderRadius: "0 20px 20px 0" }}
                      >
                        Check Status
                      </Button>
                    </div>
                  </div>

                  {enquiries.length > 0 && (
                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                      {enquiries
                        .sort(
                          (a, b) =>
                            new Date(b.created_date) - new Date(a.created_date)
                        )
                        .map((enquiry) => (
                          <div key={enquiry.id} className="card mt-3">
                            <div className="card-body">
                              <h6
                                className="card-title"
                                style={{
                                  fontFamily: "Arial, sans-serif",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                              >
                                Application Date:{" "}
                                {new Date(
                                  enquiry.created_date
                                ).toLocaleString()}
                              </h6>
                              <p
                                className="card-text"
                                style={{
                                  fontFamily: "Arial, sans-serif",
                                  fontSize: "16px",
                                  color: "#555",
                                }}
                              >
                                <strong>Name</strong>: {enquiry.first_name}{" "}
                                {enquiry.last_name} <br />
                                <strong>Email</strong>: {enquiry.email_address}{" "}
                                <br />
                                <strong>Phone</strong>: {enquiry.phone_number}
                              </p>
                              <Button
                                variant="primary"
                                onClick={() =>
                                  navigate(`/view-enquiry-status/${enquiry.id}`)
                                }
                                style={{ marginTop: "10px" }}
                              >
                                View Details
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterNew />
    </div>
  );
}

export default EnquiryStatus;

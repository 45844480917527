import React from "react";
import { ListGroup } from "react-bootstrap";

const PrintNote = ({ claimId, note }) => {
  const messages = note || [];

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        color: "#555",
        border: "1px solid #ccc",
        borderRadius: "8px",
        marginBottom: "10px",
      }}
    >
      <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
        <span style={{ color: "red" }}>Claim ID:</span> {claimId}
      </p>
      <div>
        <h6 style={{ fontSize: "18px", marginBottom: "10px" }}>
          Conversation:
        </h6>
        {messages.length > 0 ? (
          <ListGroup style={{ marginBottom: "0" }}>
            {messages.map((message, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  padding: "5px 10px",
                  textAlign: message.user === "customer" ? "left" : "right",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent:
                    message.user === "customer" ? "flex-start" : "flex-end",
                }}
                className={
                  message.user === "customer"
                    ? "customer-message"
                    : "lawyer-message"
                }
              >
                <div
                  style={{
                    padding: "8px",
                    backgroundColor:
                      message.user === "customer" ? "#d4e6f1" : "#e2f1d4",
                    borderRadius: "8px",
                    maxWidth: "80%",
                  }}
                  className="message-bubble"
                >
                  <span
                    style={{ fontSize: "12px", color: "#999" }}
                    className="message-time"
                  >
                    {message.timestamp}
                  </span>
                  <br />
                  <span
                    style={{ display: "block" }}
                    className="message-content"
                  >
                    From {message.user}:{message.note}
                  </span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p>No notes available</p>
        )}
      </div>
    </div>
  );
};

export default PrintNote;

import { createSlice } from '@reduxjs/toolkit';

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    vehicle:[],
  },
  reducers: {
    getVehicle:(state,action)=>{
      state.vehicle=action.payload
    },
    
    addtoVehicle:(state,action)=>{
      state.vehicle=[...state.vehicle,action.payload]
    },
    removeVehicle:(state,action)=>{
      const index=state.vehicle.findIndex(
        (adminUser)=>adminUser.username===action.payload.id 
      )
      let newVehicle=[...state.vehicle]
      if(index>=0){
        newVehicle.splice(index,1)
      }
      else{
        console.warn('Cant remove')
      }
      return{
        ...state,
        vehicle:newVehicle
      }
    },
    clearVehicle:(state,action)=>{
      state.vehicle=null;
    },
    updateVehicles:(state,action)=>{
      const index=state.vehicle.findIndex(
        (vehicleData)=>vehicleData.id===action.payload.id 
      )
      let newVehicle=[...state.vehicle]
      if(index>=0){
        newVehicle.splice(index,1)
        newVehicle.splice(index,0,action.payload.data)
      }
      else{
        console.warn('Cant remove')
      }
      // newVehicle.push(action.payload.data)
      return{
        ...state,
        vehicle:newVehicle
      }
    }
  },
});

export const { getVehicle,addtoVehicle,removeVehicle,clearVehicle,updateVehicle } = vehicleSlice.actions;

export const selectVehicle = state => state.vehicle.vehicle;

export default vehicleSlice.reducer;

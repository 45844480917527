import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import categoryReducer from "../Reducers/categorySlice";
import tourReducer from "../Reducers/tourSlice";
import vehicleReducer from "../Reducers/vehicleSlice";
import claimFormReducer from "../Reducers/claimFormSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["user"],
};
const reducers = combineReducers({
  category: categoryReducer,
  tour: tourReducer,
  vehicle: vehicleReducer,
  claimForm: claimFormReducer,
  // college: collegeReducer,
  // course: courseReducer,
  // client: clientReducer,
  // dashboard: dashboardReducer,
  // invoice: invoiceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
export const persistor = persistStore(store);

// src/components/PrintEnquiryStatus.js
import React from "react";
import moment from "moment";
import { Col, Row, Button, Container, Table } from "reactstrap";
import "./PrintEnquiry.css";

const PrintEnquiryStatus = React.forwardRef((props, ref) => {
  const { enquiryData } = props;

  return (
    <div style={{ padding: "30px", background: "white" }} ref={ref}>
      <div className="print_header printinvoice_header">
        <div style={{ flex: "1", border: "1px solid black", padding: "20px" }}>
          Application Details of{" "}
          <b>
            {enquiryData
              ? enquiryData?.first_name + " " + enquiryData?.last_name
              : null}
          </b>
          <hr />
          {/* <div className="form-group creatadmin">
          <div className="rowPrint">
            <div className="columnPrint">
              <label>No Of Participants With Air Fare:</label>
              <br />
              <p>
                {enquiryData
                  ? enquiryData?.number_of_participants_with_airfare
                  : null}
              </p>
            </div>
            <div className="columnPrint">
              <label>No Of Participants With Out Air Fare:</label>
              <br />
              <p>
                {enquiryData
                  ? enquiryData?.number_of_participants_with_out_airfare
                  : null}
              </p>
            </div>
          </div>
        </div> */}
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Personal Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin2">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>First Name:</label>
                <br />
                <p>{enquiryData ? enquiryData?.first_name : null}</p>
              </div>
              <div className="columnPrint">
                <label>Last Name:</label>
                <br />
                <p>{enquiryData ? enquiryData?.last_name : null}</p>
              </div>
              <div className="columnPrint">
                <label>Gender:</label>
                <br />
                <p>{enquiryData ? enquiryData?.gender : null}</p>
              </div>
              <div className="columnPrint">
                <label>Email:</label>
                <br />
                <p>{enquiryData ? enquiryData?.email_address : null}</p>
              </div>
            </div>
          </div>
          <div className="form-group creatadmin">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Contact No.:</label>
                <br />
                <p>{enquiryData ? enquiryData?.phone_number : null}</p>
              </div>
              <div className="columnPrint">
                <label>Date Of Birth:</label>
                <br />
                <p>
                  {enquiryData
                    ? moment(enquiryData?.date_of_birth).format("DD-MM-YYYY")
                    : null}
                </p>
              </div>
              <div className="columnPrint">
                <label>Passport number:</label>
                <br />
                <p>{enquiryData ? enquiryData?.passport_number : null}</p>
              </div>
              <div className="columnPrint">
                <label>Passport Expiration Date:</label>
                <br />
                <p>
                  {enquiryData
                    ? moment(enquiryData?.passport_expiration_date).format(
                        "DD-MM-YYYY"
                      )
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Address</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin2">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Address:</label>
                <br />
                <p>{enquiryData ? enquiryData?.address : null}</p>
              </div>
              <div className="columnPrint">
                <label>City:</label>
                <br />
                <p>{enquiryData ? enquiryData?.city : null}</p>
              </div>
              <div className="columnPrint">
                <label>State:</label>
                <br />
                <p>{enquiryData ? enquiryData?.state : null}</p>
              </div>
              <div className="columnPrint">
                <label>Zip Code:</label>
                <br />
                <p>{enquiryData ? enquiryData?.zip : null}</p>
              </div>
            </div>
          </div>
          <div className="form-group creatadmin">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Country:</label>
                <br />
                <p>{enquiryData ? enquiryData?.nationality : null}</p>
              </div>
            </div>
          </div>
          <div className="form-group creatadmin">
            <div style={{ display: "flex", marginTop: "10px" }}>
              <p className="provider_containertitle">Tour Details</p>
              <hr style={{ flex: "1", marginLeft: "20px" }} />
            </div>
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Interested Tour Name:</label>
                <br />
                <p>{enquiryData ? enquiryData?.intrested_tour : null}</p>
              </div>
              <div className="columnPrint">
                <label>Tour Start Date:</label>
                <br />
                <p>
                  {enquiryData
                    ? moment(enquiryData?.trip_start_date).format("DD-MM-YYYY")
                    : null}
                </p>
              </div>
              <div className="columnPrint">
                <label>Tour End Date:</label>
                <br />
                <p>
                  {enquiryData
                    ? moment(enquiryData?.trip_end_date).format("DD-MM-YYYY")
                    : null}
                </p>
              </div>
              <div className="columnPrint">
                <label>Package:</label>
                <br />
                <p>
                  {enquiryData.accommodation_only
                    ? "Accommodation Only"
                    : "Full Package"}
                </p>
              </div>
              <div className="columnPrint">
                <label>Insurance:</label>
                <br />
                <p>{enquiryData.insurance ? "Yes" : "NO"}</p>
              </div>
              {/* <div className="columnPrint">
              <label>Total Price:</label>
              <br />
              <p>
                {" "}
                {enquiryData.total_price
                  ? `$ ${enquiryData.total_price}`
                  : `$${0}`}
              </p>
            </div> */}
              {/* <div className="columnPrint">
              <label>Mode Of Tour:</label>
              <br />
              <p>{enquiryData ? enquiryData?.mode_of_tour : null}</p>
            </div> */}
            </div>
          </div>
          {/* <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Card Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Payment Method:</label>
                <br />
                <p>{enquiryData ? enquiryData?.payment_method : null}</p>
              </div>

              {enquiryData?.payment_method == "Card" ? (
                <>
                  {" "}
                  <div
                    // className="columnPrint"
                    style={{ float: "left", width: "20%", height: "auto" }}
                  >
                    <label>Card Holder Name:</label>
                    <br />
                    <p>{enquiryData ? enquiryData?.card_holder_name : null}</p>
                  </div>
                  <div
                    //  className="columnPrint"
                    style={{ float: "left", width: "15%", height: "auto" }}
                  >
                    <label>Card No.:</label>
                    <br />
                    <p>{enquiryData ? enquiryData?.card_number : null}</p>
                  </div>
                
                  <div
                    // className="columnPrint"
                    style={{ float: "left", width: "15%", height: "auto" }}
                  >
                    <label>Expiry Date:</label>
                    <br />
                    <p>
                      {enquiryData?.card_expiry_month &&
                      enquiryData?.card_expiry_year
                        ? `${enquiryData.card_expiry_month}/${enquiryData.card_expiry_year}`
                        : ""}
                    </p>
                  </div>
                  <div
                    // className="columnPrint"
                    style={{ float: "left", width: "15%", height: "auto" }}
                  >
                    <label>CVV:</label>
                    <br />
                    <p>{enquiryData ? enquiryData?.card_cvv : null}</p>
                  </div>
                </>
              ) : null}
            </div>
          
          </div> */}
          {/* <div style={{ display: "flex", marginTop: "10px" }}>
          <p className="provider_containertitle">Enquiry Status</p>
          <hr style={{ flex: "1", marginLeft: "20px" }} />
        </div>
        <div className="form-group creatadmin">
          <div className="rowPrint">
            <div className="columnPrint">
              <label>Enquiy Status:</label>
              <br />
              <p>{enquiryData ? enquiryData?.enquiry_status : null}</p>
            </div>
          </div> */}
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Payment Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin">
            <div className="rowPrint">
              {/* <div className="columnPrint">
              <label>Payment Status:</label>
              <br />
              <p>
                {enquiryData?.payment_status ? "Completed" : "Not Completed"}
              </p>
            </div> */}
              <div className="columnPrint">
                <label>Total Cost:</label>
                <br />
                <p>
                  {" "}
                  {enquiryData.total_price
                    ? `$ ${enquiryData.total_price}`
                    : `$${0}`}
                </p>
              </div>
              <div className="columnPrint">
                <label>Open Balance:</label>
                <br />
                <p>
                  {" "}
                  {enquiryData.pending_amount
                    ? `$ ${enquiryData.pending_amount}`
                    : `$${0}`}
                </p>
              </div>
            </div>
            <div className="rowPrint">
              {enquiryData.Transactions &&
              enquiryData.Transactions.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Payment Method</th>
                      <th>Card Number</th>
                      <th>Transaction ID</th>
                      <th>Payment From</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiryData.Transactions.map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td>{index + 1}</td>
                        <td>{transaction.payment_method}</td>
                        <td>
                          {transaction.payment_method === "Card" &&
                          transaction.card_number !== "Non" ? (
                            <p style={{ margin: "0px" }}>
                              ******{transaction.card_number}
                            </p>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{transaction.transaction_id}</td>
                        <td>{transaction.payment_from}</td>
                        <td>${transaction.amount}</td>
                        <td>
                          {new Date(
                            transaction.created_date
                          ).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No transaction details found.</p>
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Billing Address</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin2">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Address:</label>
                <br />
                <p>{enquiryData ? enquiryData?.billing_address : null}</p>
              </div>
              <div className="columnPrint">
                <label>City:</label>
                <br />
                <p>{enquiryData ? enquiryData?.billing_city : null}</p>
              </div>
              <div className="columnPrint">
                <label>State:</label>
                <br />
                <p>{enquiryData ? enquiryData?.billing_state : null}</p>
              </div>
              <div className="columnPrint">
                <label>Zip Code:</label>
                <br />
                <p>{enquiryData ? enquiryData?.billing_zip : null}</p>
              </div>
            </div>
          </div>
          <div className="form-group creatadmin">
            <div className="rowPrint">
              <div className="columnPrint">
                <label>Country:</label>
                <br />
                <p>{enquiryData ? enquiryData?.billing_country : null}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintEnquiryStatus;

import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import FooterNew from "../../NavBar/FooterNew";
import Nav from "../../NavBar/Nav";
import attachmenturl from "./../../../api/attachmenturl";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import base_url from "./../../../api/bootapi";
import { withWidth } from "@material-ui/core";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { Link } from "react-router-dom";

const VehicleDetails = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [baseimage, setbaseimage] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleSelectCarousel = (selectedIndex, e) => {
    if (e && e.source === "select") return; // Prevent default behavior
    setCarouselIndex(selectedIndex);
  };

  useEffect(() => {
    getVehicleDetails();
    getAllAmenities();
  }, []);
  const handleBook = () => {
    navigate(`/vehicle-book-request/${params.vehicle_id}`);
  };

  const handleGoBack = () => {
    navigate(`/vehicles`);
  };

  const getAllAmenities = () => {
    setbackdropopen(true);
    axios.get(`${base_url.api1}/tourapp/amenities`).then(
      (response) => {
        setAmenitiesList(response.data.Amenities);

        setbackdropopen(false);
      },
      (error) => {
        let errorResp = JSON.stringify(error.response.status);

        setAmenitiesList([]);
        setbackdropopen(false);
        //console.log(JSON.stringify(error));
      }
    );
  };

  const getAmenityTitle = (keyValue) => {
    const amenity = amenitiesList.find(
      (amenity) => amenity.key_value === keyValue
    );
    return amenity ? amenity.title : null;
  };

  const filterVideoFiles = (files) => {
    // Check if the input is an array and not empty
    if (!Array.isArray(files) || files.length === 0) {
      return [];
    }

    // Define a list of valid image file extensions
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".bmp"];

    // Filter out files that do not have image extensions
    return files.filter((file) => {
      const fileExtension = file.image
        .substring(file.image.lastIndexOf("."))
        .toLowerCase();
      return !imageExtensions.includes(fileExtension);
    });
  };

  const filterImageFiles = (files) => {
    // Check if the input is an array and not empty
    if (!Array.isArray(files) || files.length === 0) {
      return [];
    }

    // Define a list of valid image file extensions
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".bmp"];

    // Filter out files that have image extensions
    return files.filter((file) => {
      const fileExtension = file.image
        .substring(file.image.lastIndexOf("."))
        .toLowerCase();
      return imageExtensions.includes(fileExtension);
    });
  };

  const isImage = (url) => {
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".bmp"];
    const fileExtension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const getVehicleDetails = () => {
    axios
      .get(`${base_url.api1}/tourapp/vehicle_get?id=` + params.vehicle_id)
      .then((response) => {
        let vehicleDetails = response.data.vehicle;
        setVehicleData({
          availability: vehicleDetails.availability,
          color: vehicleDetails.color,
          created_date: vehicleDetails.created_date,
          id: vehicleDetails.id,
          number_of_seat: vehicleDetails.number_of_seat,
          cost_per_day: vehicleDetails.cost_per_day,
          cost_per_km: vehicleDetails.cost_per_km,
          distance_per_day: vehicleDetails.distance_per_day,
          type_of_vehicle: vehicleDetails.type_of_vehicle,
          updated_date: vehicleDetails.updated_date,
          vehicle_facility: vehicleDetails.vehicle_facility,
          vehicle_model: vehicleDetails.vehicle_model,
          vehicle_no: vehicleDetails.vehicle_no,
          station_of_vehicle: vehicleDetails.station_of_vehicle,
          manufactured_year: vehicleDetails.manufactured_year,
        });
        setSelectedAmenities(vehicleDetails.amenities);
        setbaseimage(vehicleDetails.images);

        // const imageFiles = filterImageFiles(vehicleDetails.images);
        // const videoFiles = filterVideoFiles(vehicleDetails.images);
        // if (imageFiles.length > 0) {
        //   setbaseimage(imageFiles);
        // }
        // if (videoFiles.length > 0) {
        //   setSelectedVideo(videoFiles[0]);
        //   //console.log("videoFile", attachmenturl + videoFiles[0].image);
        //   setSelectedVideoUrl(attachmenturl + videoFiles[0].image);
        // }
        setbackdropopen(false);
      })
      .catch((error) => {
        //console.log("Error");
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />
      <div className="container">
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul class="breadcrums">
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link">
              Linsoa VIP Transportation
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="/vehicles" class="b-link ">
              The Fleet
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link active">
              Vehicle Details
            </Link>
          </ul>
        </div>
        <Container
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            border: "1px solid #dee2e6",
            marginTop: "36px",
            marginBottom: "85px",
          }}
        >
          {vehicleData && (
            <>
              <Row className="my-4">
                <Col>
                  <h1>{vehicleData.vehicle_model}</h1>
                </Col>
                <Col className="text-right">
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <Button
                      variant="primary"
                      onClick={handleBook}
                      style={{ width: "170px" }}
                    >
                      Book
                    </Button>{" "}
                    <Button
                      variant="secondary"
                      onClick={handleGoBack}
                      style={{ width: "170px" }}
                    >
                      Go Back
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className="custom-carousel-container">
                    {baseimage.length > 0 ? (
                      <>
                        <Carousel
                          activeIndex={carouselIndex}
                          onSelect={handleSelectCarousel}
                          indicators={false}
                          controls={false}
                        >
                          {baseimage.map((item, index) => (
                            <Carousel.Item key={index}>
                              {isImage(item.image) ? (
                                <img
                                  className="d-block w-100"
                                  src={`${base_url.api1}${item.image}`}
                                  alt={`Vehicle ${index + 1}`}
                                  height="462"
                                />
                              ) : (
                                <Player
                                  playsInline
                                  src={`${base_url.api1}${item.image}`}
                                  width="100%"
                                  height="350"
                                  id="vehicleDetail"
                                />
                              )}
                            </Carousel.Item>
                          ))}
                        </Carousel>
                        <div className="custom-carousel-controls">
                          <span
                            className="custom-carousel-control prev-control"
                            onClick={() =>
                              setCarouselIndex(
                                carouselIndex === 0
                                  ? baseimage.length - 1
                                  : carouselIndex - 1
                              )
                            }
                          >
                            &#10094;
                          </span>
                          <span
                            className="custom-carousel-control next-control"
                            onClick={() =>
                              setCarouselIndex(
                                carouselIndex === baseimage.length - 1
                                  ? 0
                                  : carouselIndex + 1
                              )
                            }
                          >
                            &#10095;
                          </span>
                        </div>
                      </>
                    ) : (
                      <img
                        src="No_image_available.png"
                        className="d-block w-100"
                        height="400"
                        alt="No Image Available"
                      />
                    )}
                  </div>
                </Col>

                <Col md={4}>
                  <Card>
                    <Card.Body>
                      {vehicleData?.type_of_vehicle && (
                        <Card.Text>
                          <b>Type of Vehicle:</b> {vehicleData.type_of_vehicle}
                        </Card.Text>
                      )}
                      {vehicleData?.vehicle_model && (
                        <Card.Text>
                          <b>Vehicle Model:</b> {vehicleData.vehicle_model}
                        </Card.Text>
                      )}
                      {vehicleData?.manufactured_year && (
                        <Card.Text>
                          <b>Manufactured Year:</b>{" "}
                          {vehicleData.manufactured_year}
                        </Card.Text>
                      )}
                      {vehicleData?.color && (
                        <Card.Text>
                          <b>Color:</b> {vehicleData.color}
                        </Card.Text>
                      )}
                      {vehicleData?.vehicle_no && (
                        <Card.Text>
                          <b>Vehicle Number:</b> {vehicleData.vehicle_no}
                        </Card.Text>
                      )}

                      {vehicleData?.number_of_seat && (
                        <Card.Text>
                          <b>Number of Seats:</b> {vehicleData.number_of_seat}
                        </Card.Text>
                      )}
                      {vehicleData?.station_of_vehicle && (
                        <Card.Text>
                          <b>Location of Vehicle:</b>{" "}
                          {vehicleData.station_of_vehicle}
                        </Card.Text>
                      )}
                      {/* {vehicleData?.vehicle_facility && (
                      <Card.Text>
                        <b>Vehicle Facility:</b>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: vehicleData.vehicle_facility,
                          }}
                        />
                      </Card.Text>
                    )} */}

                      {vehicleData?.cost_per_km && (
                        <Card.Text>
                          <b>Cost per KM:</b> {`$${vehicleData.cost_per_km}`}
                        </Card.Text>
                      )}
                      {vehicleData?.cost_per_day && (
                        <Card.Text>
                          <b>Cost per Day:</b> {`$${vehicleData.cost_per_day}`}
                        </Card.Text>
                      )}
                      {vehicleData?.distance_per_day && (
                        <Card.Text>
                          <b>Min Km per Day:</b>{" "}
                          {`${vehicleData.distance_per_day} KM`}
                        </Card.Text>
                      )}

                      {vehicleData?.availability && (
                        <Card.Text>
                          <b>Availability:</b>{" "}
                          {vehicleData.availability
                            ? "Available"
                            : "Not Available"}
                        </Card.Text>
                      )}
                      {selectedAmenities?.length > 0 && (
                        <Card.Text>
                          <b>Amenities:</b>{" "}
                          {selectedAmenities
                            .map((key) => getAmenityTitle(key))
                            .filter((amenity) => amenity)
                            .join(", ")}
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
      <FooterNew />
    </>
  );
};

export default VehicleDetails;

import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Col, Row, Button, Container, Table } from "reactstrap";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import base_url from "../../api/bootapi";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import ReactToPrint from "react-to-print";
import PrintEnquiryStatus from "./PrintEnquiryStatus";
import PaymentModal from "./PaymentModal";

const ViewEnquiryStatus = () => {
  const { enquiry_id } = useParams(); // Extract enquiry_id from URL params
  const [enquiryData, setEnquiryData] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const componentRef = useRef();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [amount, setAmount] = useState("");

  const openPaymentModal = () => setPaymentModalOpen(true);
  const closePaymentModal = () => setPaymentModalOpen(false);

  const handlePaymentSubmit = (event) => {
    event.preventDefault();
    // Handle payment submission logic here
    console.log("Payment amount:", amount);
    closePaymentModal(); // Close modal after submitting payment
  };

  useEffect(() => {
    fetchEnquiryData();
  }, [enquiry_id]);

  const fetchEnquiryData = async () => {
    setBackdropOpen(true);
    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/trip_enquiry?id=${enquiry_id}`
      );
      setEnquiryData(response.data.Enquiry[0]);
      setAmount(response.data.Enquiry[0].pending_amount);
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    } finally {
      setBackdropOpen(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Nav />
      <div className="container">
        <div
          className="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul className="breadcrums">
            <Link to="/" className="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link onClick={goBack} className="b-link">
              My Booking
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" className="b-link active">
              Application Status
            </Link>
          </ul>
        </div>

        <Container
          className="enquiry-view-container"
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "15px",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <h4>
            Application Details of{" "}
            {`${enquiryData.first_name} ${enquiryData.last_name}`}
          </h4>

          {/* Personal Details Section */}
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Personal Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin2">
            <Row>
              <Col md={3}>
                <label>Last Name:</label>
                <p>{enquiryData.last_name}</p>
              </Col>
              <Col md={3}>
                <label>First Name:</label>
                <p>{enquiryData.first_name}</p>
              </Col>

              <Col md={3}>
                <label>Gender:</label>
                <p>{enquiryData.gender}</p>
              </Col>
              <Col md={3} style={{ position: "relative" }}>
                <label>Date Of Birth:</label>
                <p>{moment(enquiryData.date_of_birth).format("DD-MM-YYYY")}</p>
              </Col>
            </Row>
          </div>
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>Contact No.:</label>
                <p>{enquiryData.phone_number}</p>
              </Col>
              <Col md={3}>
                <label>Email:</label>
                <p>{enquiryData.email_address}</p>
              </Col>
              <Col md={3}>
                <label>Passport Number:</label>
                <p>{enquiryData.passport_number}</p>
              </Col>
              <Col md={3}>
                <label>Passport Expiration Date:</label>
                <p>
                  {moment(enquiryData.passport_expiration_date).format(
                    "DD-MM-YYYY"
                  )}
                </p>
              </Col>
            </Row>
          </div>

          {/* Details Section */}
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Address</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin2">
            <Row>
              <Col md={3}>
                <label>Address:</label>
                <p>{enquiryData.address}</p>
              </Col>

              <Col md={3}>
                <label>City:</label>
                <p>{enquiryData.city}</p>
              </Col>
              <Col md={2}>
                <label>State:</label>
                <p>{enquiryData.state}</p>
              </Col>
              <Col md={2}>
                <label>Zip Code:</label>
                <p>{enquiryData.zip}</p>
              </Col>
              <Col md={2} style={{ position: "relative" }}>
                <label>Country:</label>
                <p>{enquiryData.nationality}</p>
              </Col>
            </Row>
          </div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Tour Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>Interested Tour Name:</label>
                <p>{enquiryData.intrested_tour}</p>
              </Col>
              <Col md={3}>
                <label>Tour Start Date:</label>
                <p>
                  {moment(enquiryData.trip_start_date).format("DD-MM-YYYY")}
                </p>
              </Col>
              <Col md={3}>
                <label>Tour End Date:</label>
                <p>{moment(enquiryData.trip_end_date).format("DD-MM-YYYY")}</p>
              </Col>
              <Col md={3}>
                <label>Package:</label>
                <p>
                  {enquiryData.accommodation_only
                    ? "Accommodation Only"
                    : "Full Package"}
                </p>
              </Col>
              <Col md={3}>
                <label>Insurance:</label>
                <p>{enquiryData.insurance ? "Yes" : "No"}</p>
              </Col>
            </Row>
          </div>

          {/* Card Details Section */}
          {/* {enquiryData.payment_method === "Card" && (
            <>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <p className="provider_containertitle">Card Details</p>
                <hr style={{ flex: "1", marginLeft: "20px" }} />
              </div>
              <div className="form-group creatadmin">
                <Row>
                  <Col md={3}>
                    <label>Payment Method:</label>
                    <p>{enquiryData.payment_method}</p>
                  </Col>
                  <Col md={3}>
                    <label>Card Holder Name:</label>
                    <p>{enquiryData.card_holder_name}</p>
                  </Col>
                  <Col md={2}>
                    <label>Card No.:</label>
                    <p>{enquiryData.card_number}</p>
                  </Col>
                  <Col md={2}>
                    <label>Expiry Date:</label>
                    <p>
                      {enquiryData.card_expiry_month &&
                      enquiryData.card_expiry_year
                        ? `${enquiryData.card_expiry_month}/${enquiryData.card_expiry_year}`
                        : ""}
                    </p>
                  </Col>
                  <Col md={2}>
                    <label>CVV:</label>
                    <p>{enquiryData.card_cvv}</p>
                  </Col>
                </Row>
              </div>
            </>
          )} */}

          {/* Payment Details Section */}
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Payment Details</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin">
            <Row>
              {/* <Col md={2}>
                <label>Payment Status:</label>
                <p>
                  {enquiryData.payment_status ? "Completed" : "Not Completed"}
                </p>
              </Col> */}
              <Col md={2}>
                <label>Total Cost:</label>
                <p>${enquiryData.total_price}</p>
              </Col>
              <Col md={2}>
                <label>Open Balance:</label>
                <p>${enquiryData.pending_amount || 0}</p>
              </Col>
              {enquiryData.Transactions &&
              enquiryData.Transactions.length > 0 ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Payment Method</th>
                      <th>Card Number</th>
                      <th>Transaction ID</th>
                      <th>Payment From</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiryData.Transactions.map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td>{index + 1}</td>
                        <td>{transaction.payment_method}</td>
                        <td>
                          {transaction.payment_method === "Card" &&
                          transaction.card_number !== "Non" ? (
                            <p style={{ margin: "0px" }}>
                              ******{transaction.card_number}
                            </p>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{transaction.transaction_id}</td>
                        <td>{transaction.payment_from}</td>
                        <td>${transaction.amount}</td>
                        <td>
                          {new Date(
                            transaction.created_date
                          ).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No transaction details found.</p>
              )}
            </Row>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p className="provider_containertitle">Billing Address</p>
            <hr style={{ flex: "1", marginLeft: "20px" }} />
          </div>
          <div className="form-group creatadmin">
            <Row>
              <Col md={3}>
                <label>Address:</label>
                <p>{enquiryData.billing_address}</p>
              </Col>
              <Col md={3}>
                <label>City:</label>
                <p>{enquiryData.billing_city}</p>
              </Col>
              <Col md={2}>
                <label>State:</label>
                <p>{enquiryData.billing_state}</p>
              </Col>
              <Col md={2}>
                <label>Zip:</label>
                <p>{enquiryData.billing_zip}</p>
              </Col>
              <Col md={2}>
                <label>Country:</label>
                <p>{enquiryData.billing_country}</p>
              </Col>
            </Row>
          </div>

          {/* Buttons at the end */}
          <Row
            className="mt-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Col
              className="text-right"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="primary"
                    onClick={handlePrint}
                    style={{ width: "200px" }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />

              <Button
                color="success"
                onClick={openPaymentModal}
                style={{ width: "200px" }}
              >
                Make Payment
              </Button>
            </Col>
          </Row>
          <div style={{ display: "none" }}>
            <PrintEnquiryStatus ref={componentRef} enquiryData={enquiryData} />
          </div>
        </Container>
      </div>
      <FooterNew />
      <PaymentModal
        isOpen={paymentModalOpen}
        onRequestClose={closePaymentModal}
        amount={amount}
        setAmount={setAmount}
        setbackdropopen={setBackdropOpen}
        enquiry_id={enquiry_id}
        fetchEnquiryData={fetchEnquiryData}
      />
    </>
  );
};

export default ViewEnquiryStatus;

import React, { useState, useEffect } from "react";
import Nav from "./../../NavBar/Nav";
import Footer from "./../../NavBar/Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "./../../../api/bootapi";
import attachmenturl from "./../../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { getVehicle, selectVehicle } from "./../../Reducers/vehicleSlice";
import { useParams, Link, useNavigate } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
import { Col, Row, Button } from "reactstrap";
import { Carousel } from "react-bootstrap";

import StartBookingModal from "./StartBookingModal";
import FooterNew from "../../NavBar/FooterNew";
import VehicleDetailsModal from "./VehicleDetails";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
export default function Vehicles() {
  const [backdropopen, setbackdropopen] = useState(false);
  const [vehicleName, setVehicleName] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const allVehicle = useSelector(selectVehicle);
  //console.log(params.vehicle_type);

  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleSelectCarousel = (selectedIndex, e) => {
    if (e && e.source === "select") return; // Prevent default behavior
    setCarouselIndex(selectedIndex);
  };

  const openModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVehicle(null);
  };

  useEffect(() => {
    //console.log("Params----->", params);
    getData();
  }, []);
  useEffect(() => {
    getVehicleByType();
  }, [params.vehicle_type]);

  // Function to restructure the data
  function restructureSliceTourData(data) {
    const restructuredData = [];

    data.forEach((array) => {
      const newArray = array.map((vehicle) => {
        // Separate image files and non-image files into imgs and videos arrays
        const imgs = [];
        const videos = [];

        vehicle.imgs.forEach((img) => {
          const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"]; // Add more extensions as needed

          // Check if the image file has one of the recognized extensions
          const isImage = imageExtensions.some((ext) =>
            img.image.toLowerCase().endsWith(ext)
          );

          if (isImage) {
            imgs.push(img);
          } else {
            videos.push(img);
          }
        });

        // Create a new object with separate imgs and videos arrays
        const newVehicle = {
          ...vehicle,
          imgs: imgs,
          videos: videos,
        };

        return newVehicle;
      });

      restructuredData.push(newArray);
    });

    return restructuredData;
  }
  const isImage = (url) => {
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".bmp"];
    const fileExtension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  const getVehicleByType = (vehicleType) => {
    setbackdropopen(true);
    if (vehicleType) {
      axios
        .get(
          `${base_url.api1}/tourapp/vehicle_list?status=True&vehicle=` +
            vehicleType
        )
        .then(
          (response) => {
            // //console.log(response.data.Vehicle);
            // dispatch(getVehicle(response.data.Vehicle));
            let slicedTour = [];
            for (var i = 0; i < response.data.Vehicle.length; i += 3) {
              let part_slice = response.data.Vehicle.slice(i, 3 + i);
              slicedTour.push(part_slice);
            }
            // Usage example
            // const restructured = restructureSliceTourData(slicedTour);
            // //console.log("slice--->", restructured);
            dispatch(getVehicle(slicedTour));

            setbackdropopen(false);
            // setgetpageno(response.data.total_pages);
            // setTotalTours(response.data.total_count);
            // setcountnumber(value1 - 1);
            // setnumberofElements(response.data.category.length);
            // setSelectedDelete([]);
            // setsearch(false);
            // setbackdropopen(false);
            // document.getElementById("myForm").reset();
          },
          (error) => {
            dispatch(getVehicle(null));
            setbackdropopen(false);
            //console.log(JSON.stringify(error));
          }
        );
    } else {
      axios.get(`${base_url.api1}/tourapp/vehicle_list?status=True`).then(
        (response) => {
          // //console.log(response.data.Vehicle);
          // dispatch(getVehicle(response.data.Vehicle));
          let slicedTour = [];
          for (var i = 0; i < response.data.Vehicle.length; i += 3) {
            let part_slice = response.data.Vehicle.slice(i, 3 + i);
            slicedTour.push(part_slice);
          }
          // Usage example
          // const restructured = restructureSliceTourData(slicedTour);
          // //console.log("slice--->", restructured);
          dispatch(getVehicle(slicedTour));

          setbackdropopen(false);
          // setgetpageno(response.data.total_pages);
          // setTotalTours(response.data.total_count);
          // setcountnumber(value1 - 1);
          // setnumberofElements(response.data.category.length);
          // setSelectedDelete([]);
          // setsearch(false);
          // setbackdropopen(false);
          // document.getElementById("myForm").reset();
        },
        (error) => {
          dispatch(getVehicle(null));
          setbackdropopen(false);
          //console.log(JSON.stringify(error));
        }
      );
    }
  };

  const getData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?id=22` //Live
        // `${base_url.api1}/cmsapp/cms?id=44` //Test
      )
      .then(
        (response) => {
          //console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
        },
        (error) => {
          //console.log(JSON.stringify(error));
        }
      );
  };

  const handleBooking = (id) => {
    navigate(`/vehicle-book-request/${id}`);
  };
  const handleView = (data) => {
    navigate(`/view-vehicle-detail/${data.id}`);
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />
      {/* <div style={{ position: "relative", height: "35vh" }}>
        {bannerImage && (
          <img
            src={`${base_url.api1}/${bannerImage}`}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
        )}

        <div className="overlaybg">
          <div style={{ postion: "absolute" }}>
            <h1
              style={{
                width: "100%",
                marginTop: "200px",
                fontSize: "3.5rem",
                textAlign: "center",
                color: "#fff",
                fontFamily: " Caveat,cursive",
              }}
            >
              {params.vehicle_type ? vehicleName : null}
            </h1>
          </div>
        </div>
      </div> */}
      {/* <div style={{ position: "relative" }}>
        <div style={{position:"absolute",top:"0px",left:"0px",right:"0px",bottom:"0px",width:"100%",height:"100%",backgroundColor:"rgba(000,000,000, 0.7)",zIndex:"1"}}></div>
        <div className="hero-main" id="logo">
          <div className="hero-main-img">
            <img src={`https://apilinsoatours.odisoft.in/${bannerImage}`} alt="Header_Image" />
            <h1 className="tour_h1" style={{zIndex:"2"}}>
              {params.vehicle_type ? vehicleName : null}
            </h1>
          </div>
        </div>
        <Nav />
      </div> */}

      <div className="container">
        <div
          class="page-head d-flex justify-content-between p-4"
          style={{ marginTop: "1.3rem" }}
        >
          <ul class="breadcrums">
            <Link to="/" class="b-link">
              Home
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link">
              Linsoa VIP Transportation
            </Link>
            <span className="s_span ">&raquo;</span>
            <Link to="#" class="b-link active">
              The Fleet
            </Link>
          </ul>
        </div>
        <div>
          <div className="d-flex justify-content-end">
            <div className="d-flex mt-3">
              <label
                htmlFor="vehicleType"
                className="me-2 mt-2"
                style={{ whiteSpace: "nowrap" }}
              >
                Vehicle Type:
              </label>
              <select
                name="type_of_vehicle"
                onChange={(e) => getVehicleByType(e.target.value)}
                className="form-select"
              >
                <option value="">-----Select Vehicle Type------</option>
                <option value="doublebus">Double Decker Bus</option>
                <option value="bus">Bus</option>
                <option value="sprinter">Sprinter</option>
                <option value="minivan">MiniVan</option>
                <option value="car">Car</option>
              </select>
            </div>
          </div>
          {allVehicle != null ? (
            allVehicle.map((pdata, index) => (
              <div key={index} className="row mx-0">
                {pdata !== null ? (
                  pdata.map((p, index) => (
                    <div key={index} className="col-md-4 mb-4">
                      <div className="card h-100">
                        <div
                          className="colImage2"
                          style={{
                            marginTop: "-5%",
                          }}
                        >
                          <div className="custom-carousel-container">
                            {p.imgs.length > 0 ? (
                              <Carousel
                                activeIndex={carouselIndex}
                                onSelect={handleSelectCarousel}
                                indicators={false}
                                controls={false}
                              >
                                {p.imgs.map((item, idx) => (
                                  <Carousel.Item key={idx}>
                                    {isImage(item.image) ? (
                                      <img
                                        className="d-block"
                                        src={`${base_url.api1}${item.image}`}
                                        alt={`Vehicle ${idx + 1}`}
                                        height="222"
                                        width="300"
                                      />
                                    ) : (
                                      <Player
                                        playsInline
                                        src={`${base_url.api1}${item.image}`}
                                        width="300"
                                        height="200"
                                      />
                                    )}
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            ) : (
                              <img
                                src="No_image_available.png"
                                className="d-block w-100"
                                height="400"
                                alt="No Image Available"
                              />
                            )}
                            <div className="custom-carousel-controls">
                              <span
                                className="custom-carousel-control prev-control"
                                onClick={() =>
                                  setCarouselIndex(
                                    carouselIndex === 0
                                      ? p.imgs.length - 1
                                      : carouselIndex - 1
                                  )
                                }
                              >
                                &#10094;
                              </span>
                              <span
                                className="custom-carousel-control next-control"
                                onClick={() =>
                                  setCarouselIndex(
                                    carouselIndex === p.imgs.length - 1
                                      ? 0
                                      : carouselIndex + 1
                                  )
                                }
                              >
                                &#10095;
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{p.vehicle_model}</h5>
                          <p className="card-text">
                            <b>Total Seats: </b>
                            {p.number_of_seat}
                          </p>
                          <p className="card-text">
                            <b>Year: </b>
                            {p.manufactured_year}
                          </p>
                        </div>
                        <div
                          className="card-footer d-flex justify-content-between"
                          style={{ gap: "10px" }}
                        >
                          <Button
                            color="primary"
                            onClick={() => handleBooking(p.id)}
                          >
                            Book
                          </Button>
                          <Button
                            color="secondary"
                            onClick={() => handleView(p)}
                          >
                            View Details
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h1>Sorry, No Vehicles Available!</h1>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center mt-3 mb-3">
              <h1>Sorry, No Vehicles Available!</h1>
            </div>
          )}
        </div>
      </div>
      <FooterNew />
    </React.Fragment>
  );
}

import React, { useState, useEffect, useRef } from "react";
// import Nav from "../NavBar/Nav";
import NavNewDark from "../NavBar/NavNewDark";
import NavNew from "../NavBar/NavNew";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import { width } from "@mui/system";
import bannerbackground from "../../assets/bannerbackground.jpg";
import Nav from "../NavBar/Nav";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function TourTripPdf() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const priceContainerRef = useRef(null);

  const navigate = useNavigate();
  const formData = new FormData();
  const [viewModal, setViewModal] = useState(false);
  const handleViewModal = () => setViewModal(!viewModal);

  const [backdropopen, setbackdropopen] = useState(false);
  const [tripData, setTripData] = useState();

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const [airParticipants, setAirParticipants] = useState(0);
  const [airParticipantStatus, setAirParticipantStatus] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [nonAirParticipants, setNonAirParticipants] = useState(0);
  const [totalParticipantStatus, setTotalParticipantStatus] = useState(false);

  const [airFarePrice, setAirFarePrice] = useState();
  const [withoutAirFarePrice, setWithoutAirFarePrice] = useState();

  const [tripSeatData, setTripSeatData] = useState([]);
  const [tripSeatData2, setTripSeatData2] = useState([]);

  const [finalParticipants, setFinalParticipants] = useState();
  const [modeOfTour, setModeOfTour] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [imageData, setImageData] = useState();
  const [tourpdfurl, settourpdfurl] = useState("");
  const [tourstatus, settourstatus] = useState("");

  const [tourTitle, setTourTitle] = useState([]);
  const [fullPackage, setFullPackage] = useState(null);
  const [accommodation, setAccommodation] = useState(null);
  const [insurancePrice, setInsurancePrice] = useState(null);
  const [isPastTour, setIsPastTour] = useState(true);

  const params = useParams();

  const location = useLocation();

  const tourDetails = location.state?.tourDetails || null;
  const fromWhereCheck = location.state?.fromWhere || null;
  const tourName = location.state?.tour_names || null;

  //console.log("84", tourName);
  //console.log("85", fromWhereCheck);

  useEffect(() => {
    getAllTourList();
  }, [params.tour_pdf_id]);

  const getAllTourList = () => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/tourapp/get_trip?id=${params.tour_pdf_id}`)
      .then((response) => {
        console.log("tour details", response.data.Tour);
        setFullPackage(response.data.Tour[0].full_package_price);
        setAccommodation(response.data.Tour[0].accommodation_only_price);
        setInsurancePrice(response.data.Tour[0].insurance_price);

        // "tour_status": "InActive",

        if (response.data.Tour[0].tour_status === "Active") {
          setIsPastTour(false);
        }

        axios.get(`${base_url.api1}/tourapp/trip_list`, {}).then(
          (response) => {
            //console.log("100", response.data.tour);
            // setupcomingTourName(response.data.tour);
            document.getElementById("myForm").reset();
            // Find the object with matching id
            const matchingObject = response?.data?.tour?.find(
              (obj) => obj.id == params?.tour_pdf_id
            );

            // Check if a matching object was found
            if (matchingObject) {
              //console.log("Matching tour_names:", matchingObject);
              settourpdfurl(matchingObject.tour_details);
              settourstatus(matchingObject.tour_status);
            }
            setbackdropopen(false);
          },
          (error) => {
            settourpdfurl(null);
            setbackdropopen(false);

            //console.log(JSON.stringify(error.response.data));
          }
        );
      })
      .catch((err) => {
        setbackdropopen(false);
      });
  };

  const getTourDetail = () => {
    axios
      .get(`${base_url.api1}/tourapp/get_trip?id=${params.tour_pdf_id}`)
      .then((response) => {
        setFullPackage(response.data.Tour.full_package_price);
        setAccommodation(response.data.Tour.accommodation_only_price);
      })
      .catch((err) => {});
  };

  const pdfurl = location.state?.tourDetails;
  const fullURL = tourpdfurl ? `${attachmenturl}${tourpdfurl}` : null;
  const Testurl = "https://purple-bertina-48.tiiny.site";

  // const printIframe = (id) => {
  //     //console.log(id);
  //     const iframe = document.frames
  //         ? document.frames[id]
  //         : document.getElementById(id);
  //     const iframeWindow = iframe.contentWindow || iframe;

  //     iframe.focus();
  //     iframeWindow.print();
  //     return false;
  // };

  const pdfToImage = async (pdfUrl) => {
    const loadingTask = pdfjs.getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(1); // Render the first page, or loop if multiple pages are needed

    const viewport = page.getViewport({ scale: 1.5 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;

    // Convert canvas to an image URL
    return canvas.toDataURL();
  };

  const printIframe = async (pdfUrl, priceHTML) => {
    try {
      // Set loading state
      setbackdropopen(true);

      // Convert the PDF to an image
      const imageUrl = await pdfToImage(pdfUrl);

      // Create an invisible iframe
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";
      document.body.appendChild(iframe);

      // Get the iframe's document
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      // Write the content to the iframe's document
      iframeDoc.open();
      iframeDoc.write(`
        <html>
          <head>
            <style>
              body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
              }
              img {
                width: 100%;
                max-height: 1140px;
                object-fit: contain;
                border: 2px solid black;
              }
              .price-container {
                margin-top: 20px;
              }
            </style>
          </head>
          <body>
            <img src="${imageUrl}" />
          
          </body>
        </html>
      `);
      iframeDoc.close();
      //   <div class="price-container">
      //   ${priceHTML}
      // </div>
      // Wait for the content to be fully loaded and then print
      iframe.onload = () => {
        setbackdropopen(false);
        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        // Remove the iframe after printing
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 1000);
      };
    } catch (error) {
      // Handle any errors
      setbackdropopen(false);
      console.error("Error occurred while printing the iframe:", error);
    }
  };

  // const printIframe = (id, divcontents) => {
  //     var content = document.getElementById(divcontents); // Use the passed divcontents variable
  //     var pri = document.getElementById(id).contentWindow; // Use the passed id variable
  //     pri.document.open();
  //     pri.document.write(content.innerHTML);
  //     pri.document.close();
  //     pri.focus();
  //     pri.print();
  // };

  const handleEnrollmentClick = () => {
    // Navigate to the specified pathname on button click
    navigate({
      pathname: `/view-tour-Enrollment/${params.tour_pdf_id}/`,
      state: { tour_names: tourName },
    });
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />

        <div className="container">
          <div
            className="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul className="breadcrums">
              <Link to="/" className="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" className="b-link">
                Linsoa Tours
              </Link>
              <span className="s_span ">&raquo;</span>
              <Link to="#" className="b-link active">
                {isPastTour ? "Past Tours" : "Upcoming Tours"}
              </Link>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "108%",
            }}
          >
            <div style={{ flex: 1 }}>
              <Carousel>
                {tourpdfurl == null ? (
                  <img
                    src="No_image_available.png"
                    width="200"
                    height="200"
                    alt="No image available"
                  />
                ) : (
                  <div>
                    <iframe
                      id="tourpdf"
                      src={fullURL}
                      width="100%"
                      height="1140px"
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "1rem",
                        marginLeft: "2.5rem",
                        justifyContent: "center",
                      }}
                      className="price-container"
                    >
                      {fullPackage && (
                        <div style={{ marginRight: "2rem", width: "200px" }}>
                          <label>Full Package</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              id="fullPackage"
                              className="form-control underline-input"
                              value={`$ ${fullPackage}`}
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      {accommodation && (
                        <div style={{ marginRight: "2rem", width: "200px" }}>
                          <label>Accommodation Only</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control underline-input"
                              value={`$ ${accommodation}`}
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                      {insurancePrice && (
                        <div style={{ width: "200px" }}>
                          <label>Travel Insurance</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control underline-input"
                              value={`$ ${insurancePrice}`}
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Carousel>
            </div>

            {tourpdfurl != null && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginLeft: "20px", // Adds space between the iframe and buttons
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    const priceHTML =
                      document.querySelector(".price-container").outerHTML;
                    printIframe(fullURL, priceHTML);
                  }}
                  style={{
                    fontWeight: "bold",
                    borderRadius: "40px",
                    padding: "10px 35px",
                    backgroundColor: "#00627e",
                    marginBottom: "10px", // Adds space between the buttons
                  }}
                >
                  Print PDF
                </Button>
                {tourstatus === "Active" && (
                  <Button
                    variant="primary"
                    onClick={handleEnrollmentClick}
                    style={{
                      fontWeight: "bold",
                      borderRadius: "40px",
                      padding: "10px 35px",
                      backgroundColor: "#00627e",
                    }}
                  >
                    Enrollment
                  </Button>
                )}
              </div>
            )}
          </div>

          <div className="tourdetails_grid"></div>
        </div>
        <FooterNew />
      </React.Fragment>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Nav from "../NavBar/Nav";
import Footer from "../NavBar/Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { width } from "@mui/system";
import FooterNew from "../NavBar/FooterNew";

export default function TourDetails() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const formData = new FormData();
  const [viewModal, setViewModal] = useState(false);
  const handleViewModal = () => setViewModal(!viewModal);

  const [backdropopen, setbackdropopen] = useState(false);
  const [tripData, setTripData] = useState();

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const [airParticipants, setAirParticipants] = useState(0);
  const [airParticipantStatus, setAirParticipantStatus] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [nonAirParticipants, setNonAirParticipants] = useState(0);
  const [totalParticipantStatus, setTotalParticipantStatus] = useState(false);

  const [airFarePrice, setAirFarePrice] = useState();
  const [withoutAirFarePrice, setWithoutAirFarePrice] = useState();

  const [tripSeatData, setTripSeatData] = useState([]);
  const [tripSeatData2, setTripSeatData2] = useState([]);

  const [finalParticipants, setFinalParticipants] = useState();
  const [modeOfTour, setModeOfTour] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [imageData, setImageData] = useState();
  const [tourTitle, setTourTitle] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const allTour = useSelector(selectTour);

  const [f, setf] = useState();

  useEffect(() => {
    getTourByTourId(params.tour_id);
    getImagesByTourId(params.tour_id);
  }, []);

  const getTourByTourId = (value1) => {
    setbackdropopen(true);
    axios.get(`${base_url.api1}/tourapp/get_tour?id=` + params.tour_id).then(
      (response) => {
        setTourTitle(response.data.Tour[0].tour_names);
        setTripData(response.data.Tour[0]);
        let remaining_seats =
          response.data.Tour[0].total_seats - response.data.Tour[0].booked_seat;
        setAirFarePrice(
          response.data.Tour[0].with_airfare_price.match(/\d+/)[0]
        );
        setWithoutAirFarePrice(
          response.data.Tour[0].with_out_airfare_price.match(/\d+/)[0]
        );
        setFinalParticipants(remaining_seats);
        let remaining_obj = [];
        for (let i = 1; i <= remaining_seats; i++) {
          remaining_obj.push(i);
        }
        setTripSeatData(remaining_obj);
        setTripSeatData2(remaining_obj);
        // dispatch(getTour(response.data.Tour));
        setbackdropopen(false);
      },
      (error) => {
        // dispatch(getTour(null));
        setTourTitle();
        setbackdropopen(false);
        //console.log(JSON.stringify(error));
      }
    );
  };

  // const handleChangeAir = (e) =>{
  //   let remaining_data = finalParticipants-e.target.value;
  //   let remaining_obj = [];
  //   for (let i = 1; i <= remaining_data; i++) {
  //     remaining_obj.push(i);
  //   }
  //   setTripSeatData2(remaining_obj);
  // }

  // const handleChangeNonAir = (e) =>{
  //   let remaining_data = finalParticipants-e.target.value;
  //   let remaining_obj = [];
  //   for (let i = 1; i <= remaining_data; i++) {
  //     remaining_obj.push(i);
  //   }
  //   setTripSeatData(remaining_obj);
  // }

  const [allYear, setAllYear] = useState();
  const getYear = () => {
    let year_obj = [];
    for (let i = 2000; i < 3000; i++) {
      year_obj.push(i);
    }
    setAllYear(year_obj);
  };

  const getImagesByTourId = (value1) => {
    setbackdropopen(true);
    axios.get(`${base_url.api1}/tourapp/get_image?tour=` + params.tour_id).then(
      (response) => {
        setImageData(response.data.Gallery);
        setbackdropopen(false);
      },
      (error) => {
        setImageData();
        setbackdropopen(false);
        //console.log(JSON.stringify(error));
      }
    );
  };

  const onSubmit = (data2) => {
    if (!country1) {
      setCountryValid(true);
      return;
    }
    if (!region) {
      setRegionValid(true);
      return;
    }
    data2.tour_id = params.tour_id;
    data2.trip_start_date = tripData?.duartion_start_date;
    data2.trip_end_date = tripData?.duartion_end_date;
    data2.mode_of_tour = modeOfTour;
    data2.number_of_participants_with_airfare = airParticipants;
    data2.number_of_participants_with_out_airfare = nonAirParticipants;
    data2.total_price =
      airParticipants * airFarePrice + nonAirParticipants * airFarePrice;
    data2.intrested_tour = tourTitle;
    data2.nationality = country1;
    data2.enquiry_status = "Active";
    data2.place_of_birth = country1;
    data2.state = region;
    data2.user = 1;
    data2.date_of_birth = moment(data2.date_of_birth).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    data2.passport_expiration_date = moment(
      data2.passport_expiration_date
    ).format("YYYY-MM-DDThh:mm:ss");
    data2.trip_start_date = moment(data2.trip_start_date).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    data2.trip_end_date = moment(data2.trip_end_date).format(
      "YYYY-MM-DDThh:mm:ss"
    );

    let data = {
      tour_id: data2.tour_id,
      trip_start_date: data2.trip_start_date,
      trip_end_date: data2.trip_end_date,
      mode_of_tour: data2.mode_of_tour,
      number_of_participants_with_airfare:
        data2.number_of_participants_with_airfare,
      number_of_participants_with_out_airfare:
        data2.number_of_participants_with_out_airfare,
      total_price: data2.total_price,
      intrested_tour: data2.intrested_tour,
      nationality: data2.nationality,
      enquiry_status: data2.enquiry_status,
      place_of_birth: data2.place_of_birth,
      state: data2.state,
      user: data2.user,
      date_of_birth: data2.date_of_birth,
      passport_expiration_date: data2.passport_expiration_date,
      trip_start_date: data2.trip_start_date,
      trip_end_date: data2.trip_end_date,
      first_name: data2.first_name,
      last_name: data2.last_name,
      gender: data2.gender,
      email_address: data2.email_address,
      phone_number: data2.phone_number,
      date_of_birth: data2.date_of_birth,
      city: data2.city,
      address: data2.address,
      zip: data2.zip,
      passport_number: data2.passport_number,
      payment_method: data2.payment_method,
      card_holder_name: data2.card_holder_name,
      card_expiry_month: cardDetails.card_expiry_year.substring(0, 2),
      card_expiry_year: cardDetails.card_expiry_year.substring(5, 7),
    };
    let submitData = {
      data: data,
      card_number: cardDetails.card_number,
      card_cvv: cardDetails.card_cvv,
    };
    //console.log(data);
    formData.append("data", JSON.stringify(submitData));

    setbackdropopen(true);
    axios.post(`${base_url.api1}/tourapp/enquiry_and_pay`, formData).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          setbackdropopen(false);
          handleViewModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Payment Successful",
            showCloseButton: true,
            showConfirmButton: false,
          });
          document.getElementById("myForm").reset();
          setcountry("United States");
          setregion("New York");
        } else {
          setbackdropopen(false);
          handleViewModal();
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Payment failed",
            showCloseButton: true,
            showConfirmButton: false,
          });
          document.getElementById("myForm").reset();
          setcountry("United States");
          setregion("New York");
        }
      },
      (error) => {
        setbackdropopen(false);
        handleViewModal();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    );
  };

  const [cardDetails, setCardDetails] = useState({
    card_number: null,
    card_expiry_month: null,
    card_expiry_year: null,
    card_cvv: null,
  });

  const handleChangeCardNumber = (e) => {
    setCardDetails({
      ...cardDetails,
      card_number: e.target.value,
    });
  };
  //console.log(cardDetails);

  const handleChangeExpiryDate = (e) => {
    setCardDetails({
      ...cardDetails,
      card_expiry_year: e.target.value,
    });
  };

  const handleChangeCVC = (e) => {
    setCardDetails({
      ...cardDetails,
      card_cvv: e.target.value,
    });
  };

  const [isPersonalOpen, setIsPersonalOpen] = useState(true);

  const personalToggle = () => setIsPersonalOpen(!isPersonalOpen);

  const [isAddressOpen, setIsAddressOpen] = useState(false);

  const addressToggle = () => setIsAddressOpen(!isAddressOpen);

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const paymentToggle = () => setIsPaymentOpen(!isPaymentOpen);

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        <div style={{ position: "relative", height: "80vh" }}>
          <img
            src="assets/image/inside-header'.jpg"
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div>

        <div className="container">
          <Row className="px-0">
            <Col lg={9} className="carosol_img">
              <Carousel>
                {imageData != null ? (
                  imageData &&
                  imageData.map((p, index) => (
                    <div>
                      <img
                        className="carosol_img"
                        src={attachmenturl + p.image}
                        width="200"
                        height="500"
                      />
                    </div>
                  ))
                ) : (
                  <img src="No_image_available.png" width="200" height="200" />
                )}
              </Carousel>
            </Col>
            <Col
              lg={3}
              // className="colData px-0 d-flex align-items-center justify-content-center"
              className="colData px-0 d-flex tour_booking_hide"
            >
              <div
                className="trip_booking_main"
                style={{
                  padding: "10px",
                  backgroundColor: "#fff",
                  boxShadow: " 0px 0px 5px 2px rgb(192, 192, 192)",
                }}
              >
                <div className="text-center">
                  <p className="trip_booking">
                    <b>START BOOKING NOW</b>
                  </p>
                </div>
                <form className="tourdetails_form">
                  <div style={{ marginTop: "5%" }}>
                    <Row className="px-0">
                      <Col md={7}>
                        <p className="participant">
                          <b>No Of Participants With Air Fare: </b>
                        </p>
                      </Col>
                      <Col md={5}>
                        <select
                          className="selectdrop"
                          style={{ fontSize: "15px" }}
                          onChange={(e) => {
                            setAirParticipants(e.target.value);
                            setAirParticipantStatus(false);
                            setTotalParticipantStatus(false);
                            // handleChangeAir(e);
                          }}
                          // {...register("number_of_participants_with_airfare", { required: true })}
                          required
                        >
                          <option value="0">0</option>
                          {tripSeatData == null ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : (
                            tripSeatData?.map((val) => (
                              <option value={val}>{val}</option>
                            ))
                          )}
                        </select>
                      </Col>
                    </Row>
                    <Row className="participant_row px-0">
                      <Col md={7}>
                        <p className="participant">
                          <b>No Of Participants With Out Air Fare: </b>
                        </p>
                      </Col>
                      <Col md={5}>
                        <select
                          className="selectdrop"
                          onChange={(e) => {
                            setNonAirParticipants(e.target.value);
                            setAirParticipantStatus(false);
                            setTotalParticipantStatus(false);
                            // handleChangeNonAir(e);
                          }}
                          // {...register("number_of_participants_with_out_airfare", { required: true })}
                          required
                        >
                          <option value="0">0</option>
                          {tripSeatData2 == null ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : (
                            tripSeatData2?.map((val) => (
                              <option value={val}>{val}</option>
                            ))
                          )}
                        </select>
                      </Col>
                    </Row>
                    <Row
                      style={{ marginTop: "-5px" }}
                      className="participant_row px-0"
                    >
                      <Col md={7}>
                        <p className="participant">
                          <b>Final Price: </b>
                        </p>
                      </Col>
                      <Col md={5}>
                        <span
                          style={{
                            float: "right",
                            marginRight: "10px",
                            fontSize: "15px",
                          }}
                        >
                          <b>
                            $
                            {airParticipants * airFarePrice +
                              nonAirParticipants * withoutAirFarePrice >=
                            0
                              ? airParticipants * airFarePrice +
                                nonAirParticipants * withoutAirFarePrice
                              : 0}
                          </b>
                        </span>
                      </Col>
                    </Row>
                    <Row className="participant_row_error px-0">
                      <Col md={12}>
                        {/* {modeStatus ? (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            Select Mode Of Travel.
                          </span>
                        ) : null} */}
                        {airParticipantStatus ? (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            Select Atleast One Participiant.
                          </span>
                        ) : null}
                        {totalParticipantStatus ? (
                          <span style={{ color: "red", fontSize: "15px" }}>
                            Total Participants can not be more then{" "}
                            {tripSeatData.length}.
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="participant_row px-0">
                      <Col md={12}>
                        <div className="text-center">
                          <Button
                            onClick={() => {
                              if (
                                airParticipants == 0 &&
                                nonAirParticipants == 0
                              ) {
                                setAirParticipantStatus(true);
                                return;
                              }
                              // else if (
                              //   modeOfTour == undefined ||
                              //   modeOfTour == ""
                              // ) {
                              //   setModeStatus(true);
                              //   return;
                              // }
                              else if (
                                Number(nonAirParticipants) +
                                  Number(airParticipants) >
                                tripSeatData.length
                              ) {
                                setTotalParticipantStatus(true);
                                return;
                              } else {
                                setTotalParticipantStatus(false);
                                getYear();
                                setViewModal(true);
                              }
                            }}
                            style={{
                              // marginTop: "20px",
                              width: "60%",
                              justifyContent: "center",
                              // marginLeft: "5px",
                              background: "#ea2763",
                              border: "#ea2763",
                              borderRadius: "25px",
                              padding: "10px 0",
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </form>
                <Modal
                  show={viewModal}
                  onHide={handleViewModal}
                  size="xl"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Submit Below Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <form
                        id="myForm"
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ position: "relative" }}
                      >
                        <b
                          style={{
                            position: "absolute",
                            top: "55px",
                            bottom: "65px",
                            left: "45px",
                            width: "1px",
                            backgroundColor: "grey",
                          }}
                        ></b>

                        <div className="book_collaps">
                          <p>1</p>
                          <p>Personal Details</p>
                        </div>
                        <Collapse
                          isOpen={isPersonalOpen}
                          style={{ maxWidth: "100%" }}
                        >
                          <div style={{ padding: "0px 50px" }}>
                            <div className="form-group creatadmin2">
                              <Row>
                                <Col md={4}>
                                  <label>
                                    First Name
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tfirst"
                                    className="form-control underline-input"
                                    {...register("first_name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.first_name && (
                                    <p className="errormessage">
                                      First Name is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Last Name
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tlast"
                                    className="form-control underline-input"
                                    {...register("last_name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.last_name && (
                                    <p className="errormessage">
                                      Last Name is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Gender
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <select
                                    className="form-control"
                                    id="tgender"
                                    {...register("gender", {
                                      required: true,
                                    })}
                                  >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </select>
                                  {errors.gender && (
                                    <p className="errormessage">
                                      Gender is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Email
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="email"
                                    id="temail"
                                    className="form-control underline-input"
                                    {...register("email_address", {
                                      required: true,
                                      pattern:
                                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                                    })}
                                  />
                                  {errors.email_address && (
                                    <p className="errormessage">
                                      Email is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Contact No.
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tcontact"
                                    className="form-control underline-input"
                                    {...register("phone_number", {
                                      required: true,
                                    })}
                                  />
                                  {errors.phone_number && (
                                    <p className="errormessage">
                                      Phone is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4} style={{ position: "relative" }}>
                                  <label>
                                    Date Of Birth
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="date"
                                    id="tdate"
                                    className="form-control underline-input"
                                    {...register("date_of_birth", {
                                      required: true,
                                    })}
                                  />
                                  {errors.date_of_birth && (
                                    <p className="errormessage">
                                      Date of Birth is Required
                                    </p>
                                  )}
                                </Col>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p
                                    className="book_btn"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "black",
                                      border: "1px solid grey",
                                    }}
                                  >
                                    Cancel
                                  </p>
                                  <p
                                    className="book_btn"
                                    style={{
                                      backgroundColor: "#1591e1",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      let tfirst =
                                        document.getElementById("tfirst");
                                      let tlast =
                                        document.getElementById("tlast");
                                      let tgender =
                                        document.getElementById("tgender");
                                      let tdate =
                                        document.getElementById("tdate");
                                      let tcontact =
                                        document.getElementById("tcontact");
                                      let temail =
                                        document.getElementById("temail");
                                      if (!tfirst.value) {
                                        tfirst.style.border = "1px solid red";
                                      } else if (!tlast.value) {
                                        tlast.style.border = "1px solid red";
                                      } else if (!tgender.value) {
                                        tgender.style.border = "1px solid red";
                                      } else if (!tdate.value) {
                                        tdate.style.border = "1px solid red";
                                      } else if (!tcontact.value) {
                                        tcontact.style.border = "1px solid red";
                                      } else if (!temail.value) {
                                        temail.style.border = "1px solid red";
                                      } else {
                                        //console.log(true);
                                        setIsPersonalOpen(!isPersonalOpen);
                                        setIsAddressOpen(!isAddressOpen);
                                      }
                                    }}
                                  >
                                    Submit & Next
                                  </p>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Collapse>

                        <div className="book_collaps">
                          <p>2</p>
                          <p>Address and Passport Details</p>
                        </div>
                        <Collapse
                          isOpen={isAddressOpen}
                          style={{ maxWidth: "100%" }}
                        >
                          <div style={{ padding: "0px 50px" }}>
                            <div className="form-group creatadmin2">
                              <Row>
                                <Col md={4}>
                                  <label>
                                    Country
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <CountryDropdown
                                    className="countrydropdown2 form-control underline-input"
                                    id="tcountry"
                                    defaultOptionLabel="- Select Country -"
                                    value={country1}
                                    onChange={(val) => {
                                      setcountry(val);
                                      setCountryValid(false);
                                    }}
                                  />
                                  {countryValid ? (
                                    <p className="errormessage">
                                      Country is Required
                                    </p>
                                  ) : null}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    State
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <RegionDropdown
                                    className="countrydropdown2 form-control underline-input"
                                    defaultOptionLabel="- Select State -"
                                    id="tstate"
                                    country={country1}
                                    value={region}
                                    onChange={(val) => {
                                      setregion(val);
                                      setRegionValid(false);
                                    }}
                                  />
                                  {regionValid ? (
                                    <p className="errormessage">
                                      State is Required
                                    </p>
                                  ) : null}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    City
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tcity"
                                    className="form-control underline-input"
                                    {...register("city", { required: true })}
                                  />
                                  {errors.city && (
                                    <p className="errormessage">
                                      City is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>Address:</label>
                                  <input
                                    type="text"
                                    id="taddress"
                                    className="form-control underline-input"
                                    {...register("address", {
                                      required: true,
                                    })}
                                  />
                                </Col>
                                <Col md={4} style={{ position: "relative" }}>
                                  <label>
                                    Zip Code
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tzip"
                                    className="form-control underline-input"
                                    {...register("zip", { required: true })}
                                  />
                                  {errors.zip && (
                                    <p className="errormessage">
                                      Zip is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Passport number
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="text"
                                    id="tpassport"
                                    className="form-control underline-input"
                                    {...register("passport_number", {
                                      required: true,
                                    })}
                                  />
                                  {errors.passport_number && (
                                    <p className="errormessage">
                                      Passport is Required
                                    </p>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <label>
                                    Passport Expiration Date
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <input
                                    type="date"
                                    id="texpire"
                                    className="form-control underline-input"
                                    {...register("passport_expiration_date", {
                                      required: true,
                                    })}
                                    min={getCurrentDate()}
                                  />
                                  {errors.passport_expiration_date && (
                                    <p className="errormessage">
                                      Passport Expiry Date is Required
                                    </p>
                                  )}
                                </Col>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <p
                                    className="book_btn"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "black",
                                      border: "1px solid grey",
                                    }}
                                    onClick={() => {
                                      setIsAddressOpen(!isAddressOpen);
                                      setIsPersonalOpen(!isPersonalOpen);
                                    }}
                                  >
                                    Back
                                  </p>
                                  <p
                                    className="book_btn"
                                    style={{
                                      backgroundColor: "#1591e1",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      let tcountry =
                                        document.getElementById("tcountry");
                                      let tstate =
                                        document.getElementById("tstate");
                                      let tcity =
                                        document.getElementById("tcity");
                                      let taddress =
                                        document.getElementById("taddress");
                                      let tzip =
                                        document.getElementById("tzip");
                                      let tpassport =
                                        document.getElementById("tpassport");
                                      let texpire =
                                        document.getElementById("texpire");
                                      if (!tcountry.value) {
                                        tcountry.style.border = "1px solid red";
                                      } else if (!tstate.value) {
                                        tstate.style.border = "1px solid red";
                                      } else if (!tcity.value) {
                                        tcity.style.border = "1px solid red";
                                      } else if (!taddress.value) {
                                        taddress.style.border = "1px solid red";
                                      } else if (!tzip.value) {
                                        tzip.style.border = "1px solid red";
                                      } else if (!tpassport.value) {
                                        tpassport.style.border =
                                          "1px solid red";
                                      } else if (!texpire.value) {
                                        texpire.style.border = "1px solid red";
                                      } else {
                                        setIsAddressOpen(!isAddressOpen);
                                        setIsPaymentOpen(!isPaymentOpen);
                                      }
                                    }}
                                  >
                                    Submit & Next
                                  </p>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Collapse>

                        <div className="book_collaps">
                          <p>3</p>
                          <p>Payment</p>
                        </div>
                        <Collapse
                          isOpen={isPaymentOpen}
                          style={{ maxWidth: "100%" }}
                        >
                          <div style={{ padding: "0px 50px" }}>
                            <div className="form-group creatadmin2">
                              <Row>
                                <Col md={3}>
                                  <label>
                                    Payment Method
                                    <span style={{ color: "red" }}>*</span>:
                                  </label>
                                  <select
                                    className="form-control"
                                    {...register("payment_method", {
                                      required: true,
                                    })}
                                    onChange={(e) =>
                                      setPaymentMethod(e.target.value)
                                    }
                                  >
                                    <option value="Card">Card</option>
                                    <option value="Check">Check</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Zelle">Zelle</option>
                                  </select>
                                  {errors.payment_method && (
                                    <p className="errormessage">
                                      Payment Method is Required
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </div>

                            {paymentMethod == "Card" ? (
                              <>
                                <div className="form-group creatadmin2">
                                  <Row>
                                    <Col md={5}>
                                      <label>
                                        Card Holder Name
                                        <span style={{ color: "red" }}>*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        {...register("card_holder_name", {
                                          required: (value) => {
                                            if (paymentMethod == "Card") {
                                              return true;
                                            }
                                          },
                                        })}
                                      />
                                      {errors.card_holder_name && (
                                        <p className="errormessage">
                                          Card Holder Name is Required
                                        </p>
                                      )}
                                    </Col>
                                    <Col md={7} style={{ marginTop: "22px" }}>
                                      <PaymentInputsWrapper {...wrapperProps}>
                                        <svg
                                          {...getCardImageProps({ images })}
                                        />
                                        <input
                                          {...getCardNumberProps({
                                            onChange: handleChangeCardNumber,
                                          })}
                                        />
                                        <input
                                          {...getExpiryDateProps({
                                            onChange: handleChangeExpiryDate,
                                          })}
                                        />
                                        <input
                                          {...getCVCProps({
                                            onChange: handleChangeCVC,
                                          })}
                                        />
                                      </PaymentInputsWrapper>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ) : null}

                            <div
                              style={{ padding: "0 20px ", marginTop: "50px" }}
                            >
                              <div>
                                <p
                                  className="book_btn"
                                  style={{
                                    display: "inline-block",
                                    backgroundColor: "#fff",
                                    color: "black",
                                    margin: "0px",
                                    border: "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setIsAddressOpen(!isAddressOpen);
                                    setIsPaymentOpen(!isPaymentOpen);
                                  }}
                                >
                                  Back
                                </p>

                                <div
                                  style={{
                                    float: "right",
                                    display: "flex",
                                    padding: "5px",
                                  }}
                                >
                                  <Button
                                    color="secondary"
                                    style={{
                                      padding: "10px 40px",
                                      marginLeft: "5px",
                                      float: "right",
                                    }}
                                    onClick={handleViewModal}
                                  >
                                    Reset
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{
                                      padding: "10px 40px",
                                      marginLeft: "5px",
                                      float: "right",
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          </Row>
          <div className="tourdetails_grid">
            <div className="touroverview">
              <div className="tourdetails_title">
                <b>TOUR OVERVIEW</b>
              </div>

              <div className="tourdetails_table">
                <p>Price:</p>
                <p>{tripData?.with_airfare_price}</p>
              </div>
              <div className="tourdetails_tablet">
                <p>Duration:</p>
                <p style={{ textAlign: "center" }}>
                  {tripData?.tour_duration}
                  <br />
                  {moment(tripData?.duartion_start_date).format(
                    "dddd MMM Do YYYY"
                  )}
                  <br />
                  to
                  <br />
                  {moment(tripData?.duartion_end_date).format(
                    "dddd MMM Do YYYY"
                  )}
                </p>
              </div>
              <div className="tourdetails_table">
                <p>Total Seats:</p>
                <p>{tripData?.total_seats}</p>
              </div>
              <div className="tourdetails_tablet">
                <p>Available Seats:</p>
                <p> {tripData?.total_seats - tripData?.booked_seat}</p>
              </div>
              <div className="tourdetails_table">
                <p>Locations:</p>
                <p>
                  {" "}
                  {tripData?.Locations != null ? (
                    tripData?.Locations &&
                    tripData?.Locations.map((p, index) => (
                      <>
                        <span
                          className="poppins_font"
                          style={{ fontSize: "13px" }}
                        >
                          <b>Place: </b>
                          {p.tour_location}
                        </span>
                        <br />
                      </>
                    ))
                  ) : (
                    <span>Sorry, No Locations Available!</span>
                  )}
                </p>
              </div>
            </div>
            <div className="touroverdesc">
              <div className="tourdetails_title">
                <b>TOUR DESCRIPTION</b>
              </div>
              <div className="desctour">
                <div
                  className="poppins_font"
                  style={{ fontFamily: "Poppins" }}
                  dangerouslySetInnerHTML={{
                    __html: tripData?.tour_short_description,
                  }}
                />
                <br />
                <div
                  className="poppins_font"
                  style={{ fontFamily: "Poppins" }}
                  dangerouslySetInnerHTML={{
                    __html: tripData?.tour_long_descript,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
}

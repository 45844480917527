import React, { useState } from "react";

import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function TravelInsurance() {
  const [backdropopen, setbackdropopen] = useState(true);
  const handleLoad = () => {
    setbackdropopen(false);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <iframe
          width="70%"
          height="600"
          src="https://www.agentmaxonline.com/agentmaxweb/widgets/quotetool.html?widgetid=1007407&accam=F202462&code=ABIYU4TLWGBGTNHC6ZWLRSKAR65GB6C5JLBJOIXR7QY3M6I5HDDMDH56TVGJ2I7DJ2X6CHWYBK7ZUHZVYXGMH2SVP57DI4GHUPG65HPUFCMKSSJQQ4H74LB6A4MX747C"
          allowFullScreen
          onLoad={handleLoad}
        ></iframe>
      </div>
      <FooterNew />
    </div>
  );
}

export default TravelInsurance;
